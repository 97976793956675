import React, { useState, useEffect } from 'react'
import { Dots } from 'react-preloaders'

import { removeTerminal, updateLicenseCode, updateTerminal } from '../api/license'
import DataGrid from './DataGrid'
import MyButton from './MyButton'

import '../styles/LicenseCodesList.css'

export default function TerminalList({ loading, setLoading, drawTerminals, terminals, onEditTerminalPress, onMoreDetailedTerminalPress }) {
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  async function updateLicenseStatus(codeId, limit, comment, licenseStatus, pageSize, pageIndex) {
    await updateLicenseCode(codeId, limit, comment, licenseStatus)
    drawTerminals({ offset: 0, limit: pageSize })
  }


  async function updatePlainAddress(terminalStrId, plainAddress, pageSize, pageIndex) {
    await updateTerminal({ strId: terminalStrId, plainAddress })
    drawTerminals({ offset: 0, limit: pageSize })
  }


  const columns = React.useMemo(
  () => [
    {
      Header: 'Номер заказа',
      accessor: 'LicenseCode.orderId',
      width: 125,
    },
    {
      Header: 'Имя клиента',
      accessor: 'LicenseCode.clientName',
      width: 200,
    },
    // {
    //   Header: 'Инн клиента',
    //   accessor: 'LicenseCode.inn',
    //   width: 125,
    // },
    {
      Header: 'Лицензионный код',
      accessor: 'licenseCodeStrId',
      width: 200,
    },
    {
      Header: 'Дата активации',
      accessor: 'createdAt',
      Cell: ({ value }) => (value ? new Date(value).toLocaleString('ru-RU', dateOptions) : ''),
      width: 160,
    },
    // {
    //   Header: 'Изменил статус кода',
    //   accessor: 'LicenseCode.User',
    //   Cell: ({ value }) => {
    //     if (value) {
    //       const displayStr = `${value.firstName} ${value.lastName}`
    //
    //       return displayStr
    //     }
    //     else {
    //       return ''
    //     }
    //   },
    //   width: 180,
    // },
    // {
    //   Header: 'Ip-адрес',
    //   accessor: 'ipAddress',
    //   width: 125,
    // },
    {
      Header: 'Последнее соединение',
      accessor: 'lastPingAt',
      Cell: ({ value }) => (value ? new Date(value).toLocaleString('ru-RU', dateOptions) : ''),
      width: 200,
    },
    // {
    //   Header: 'Местоположение',
    //   accessor: 'plainAddress',
    //   width: 300,
    //   Cell: (params) => {
    //     const [plainAddress, setPlainAddress] = useState('')
    //     const [pristine, setPristine] = useState(true)
    //     useEffect(() => {
    //       setPlainAddress(params.value)
    //       setPristine(true)
    //     }, [params.value])
    //     return (<div className="licenseCodes__row-container">
    //       <textarea
    //         value={plainAddress || ''}
    //         rows={2}
    //         className="licenseCodes__row-textarea"
    //         onChange={e => { setPlainAddress(e.target.value); setPristine(false) }}
    //       />
    //       <button
    //         className="shifterButton"
    //         disabled={pristine}
    //         onClick={async () => {
    //           await updatePlainAddress(
    //             params.row.original.strId,
    //             plainAddress,
    //             params.state.pageSize,
    //             params.state.pageIndex,
    //           )
    //
    //           setPlainAddress(params.value)
    //           setPristine(true)
    //         }}
    //       >💾</button>
    //     </div>)
    //   }
    // },
    {
      Header: 'Статус лицензии',
      accessor: 'LicenseCode.isActive',
      width: 225,
      Cell: (params) => {
        const title = params.value === true ? 'Деактивировать' : 'Активировать'

        const onToggleActivationClick = () => {
          const newIsActiveValue = !params.value

          console.log(params.row)

          updateLicenseStatus(
            params.row.original.LicenseCode.strId,
            params.row.original.LicenseCode.maxActivations,
            params.row.original.LicenseCode.comment,
            newIsActiveValue,
            params.state.pageSize,
            params.state.pageIndex,
          )
        }

        return (
          <div className='toggleActivationButtonContainer'>
            <MyButton onClick={onToggleActivationClick} mod='toggleActivationButton'>{title}</MyButton>
          </div>
        )
      }
    },
    {
      width: 225,
      id: 'moreDetailedTerminalButton',
      Cell: (params) => {
        const onButtonPress = () => {
          onMoreDetailedTerminalPress({terminalData: params.row.original, tableState: params.state})
        }

        return (
          <div className='toggleActivationButtonContainer'>
            <MyButton onClick={onButtonPress} mod='toggleActivationButton'>Подробнее</MyButton>
          </div>
        )
      }
    },
    {
      width: 40,
      id: 'editTerminalButton',
      Cell: (params) => {
        const onButtonPress = () => {
          onEditTerminalPress({terminalData: params.row.original, tableState: params.state})
        }

        return (
          <a onClick={onButtonPress} className='editTerminalButton' title='Изменить'>✏️</a>
        )
      }
    },
    {
      width: 40,
      id: 'removeTerminalButton',
      Cell: (params) => {
        const onRemoveTerminalClick = async () => {
          const licenseCodeData = params.row.original['LicenseCode']

          const licenseCodeDataStr = ` (ИНН - ${licenseCodeData.inn}, код активации - ${licenseCodeData.strId})`

          const removalConfirmed = window.confirm(`Вы уверены, что хотите удалить терминал клиента ${licenseCodeData.clientName}?${licenseCodeDataStr}`)

          if (removalConfirmed) {
            await removeTerminal({ strId: params.row.original.strId })
            drawTerminals({ offset: 0, limit: params.state.pageSize })
          }
        }

        return (
          <a onClick={onRemoveTerminalClick} className='removeTerminalButton' title='Удалить'>🗑️</a>
        )
      }
    },
  ],
  []
)
  return (
    <DataGrid
      columns={columns}
      data={terminals.data}
      fetchData={drawTerminals}
      loading={loading}
      total={terminals.total}
      id="LicenseCodesList"
    />
  )
}
