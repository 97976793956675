import superagent from 'superagent'

async function getAssemblyList(appId, branch) {
  const resp = await superagent.get(`/api/assembly/${appId}/${branch}`);
  return resp.body;
}

async function createAssembly(assembly) {
  const resp = await superagent.post('/api/assembly')
    .send(assembly);
  return resp.body;
}

async function downloadFile(strId) {
  const resp = await superagent.get(`/download/assembly/${strId}`)
    .responseType('blob')
  return resp.body;
}

async function uploadFile(file, setProgress) {
  const resp = await superagent.post('/api/assembly/zip')
    .attach('zipFile', file)
    .on('progress', ({ percent }) => setProgress(percent));
  return resp.body;
}

export default {
  getAssemblyList,
  createAssembly,
  uploadFile,
  downloadFile
}
