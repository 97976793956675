import superagent from 'superagent'

async function getApplicationList() {
  const resp = await superagent.get('/api/app/list');
  return resp.body;
}

async function getApplication(id) {
  const resp = await superagent.get(`/api/app/${id}`);
  return resp.body;
}

async function getActualVersion(id) {
  const resp = await superagent.get(`/api/app/${id}/actualAssemblyVersion`);
  return resp.body;
}

async function createApplication(app) {
  const resp = await superagent.post('/api/app')
    .send(app);
  return resp.body;
}

async function updateApplication(id, changes) {
  const resp = await superagent.put(`/api/app/${id}`)
    .send(changes);
  return resp.body;
}

async function deleteApplication(id) {
  const resp = await superagent.delete(`/api/app/${id}`);
  return resp.status;
}

export default {
  getApplicationList,
  getApplication,
  createApplication,
  updateApplication,
  getActualVersion,
  deleteApplication
}
