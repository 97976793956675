export function isAdmin(user) {
  return user.roles && user.roles.indexOf('admin') > -1
}

export function isManager(user) {
  return user.roles && !isAdmin(user) && user.roles.indexOf('manager') > -1
}

export function isKioskMaster(user) {
  return user.roles && !isAdmin(user) && !isManager(user) && user.roles.indexOf('kioskMaster') > -1
}

export function isKioskOperator(user) {
  return user.roles && !isAdmin(user) && !isManager(user) && !isKioskMaster(user) && user.roles.indexOf('kioskOperator') > -1
}