import React from "react";
import ReactDOM from 'react-dom'
import EditDataBlock from "./EditDataBlock";
import HistoryDataBlock from "./HistoryDataBlock";
import MyButton from "./MyButton";
import '../styles/MoreDetailedTerminalPopup.css'
import cn from "classnames";


const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
const showDateTime = (value) => value ? new Date(value).toLocaleString('ru-RU', dateOptions) : ''

const showUser = (value) => value ? `${value.firstName} ${value.lastName}` : ''

const licenseFieldDataList = [
  { fieldName: 'orderId', label: 'Номер заказа' },
  { fieldName: 'clientName', label: 'Имя клиента' },
  { fieldName: 'inn', label: 'ИНН' },
  { fieldName: 'strId', label: 'Лицензионный код' },
  { fieldName: 'createdAt', label:  'Дата создания лицензионного кода', showValue: showDateTime },
  { fieldName: 'updatedAt', label: 'Дата обновления лицензионного кода', showValue: showDateTime },
  { fieldName: 'User', label: 'Изменил статус кода', showValue: showUser },
  { fieldName: 'isActive', label: 'Код активирован', showValue: (value) => value ? 'Да' : 'Нет' },
  { fieldName: 'maxActivations', label: 'Максимальное количество активаций' },
  { fieldName: 'comment', label: 'Комментарий' },
]

const terminalCommonFieldDataList = [
  { fieldName: 'strId', label: 'id терминала' },
  { fieldName: 'version', label: 'Версия' },
  { fieldName: 'createdAt', label: 'Дата активации', showValue: showDateTime },
  { fieldName: 'lastPingAt', label: 'Последнее соединение', showValue: showDateTime },
  { fieldName: 'ipAddress', label: 'ip-Адрес' },
  { fieldName: 'plainAddress', label: 'Местоположение' },
]

const MoreDetailedTerminalPopup = ({terminalData, onClosePress, onEditPress}) => {
  console.log({ terminalData })
  return ReactDOM.createPortal(
    <section className={'licensePopupBackdrop'}>
      <div className={cn('terminalEditPopup', 'moreDetailedTerminalPopup')}>
        <h1>Информация о терминале</h1>

        <div className={cn('terminalEditPopupDataContainer', 'moreDetailedTerminalPopupDataContainer')}>
          <EditDataBlock
            title={'Лицензия'}
            data={terminalData.LicenseCode}
            fieldDataList={licenseFieldDataList}
            readOnly={true}
          />

          <EditDataBlock
            title={'Общие сведения'}
            data={terminalData}
            fieldDataList={terminalCommonFieldDataList}
            readOnly={true}
          />

          <HistoryDataBlock
            title={'История изменений'}
            dataList={terminalData.TerminalHistoryActions}
          />
        </div>

        <div className='terminalEditPopupButtonContainer'>
          <MyButton onClick={onClosePress} mod="myButton_white" >Закрыть</MyButton>
          <MyButton onClick={onEditPress} mod='licensePopupSaveButton'>Изменить</MyButton>
        </div>
      </div>
    </section>,
    window.document.body
  )
}

export default MoreDetailedTerminalPopup
