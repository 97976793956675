import React, { useState, useContext, useRef } from 'react'
import { Redirect } from 'react-router-dom'

import { UserContext, authorize } from '../api/auth'
import { isAdmin, isManager } from '../utils/permissions'

import MyTextInput from './MyTextInput'
import MyButton from './MyButton'

import '../styles/Login.css'

export default function Login() {
  const { user, setUser } = useContext(UserContext);
  const [errors, setErrors] = useState({
    login: false,
    username: false,
    password: false,
    server: false
  });
  const formRef = useRef(null);

  const redirect = checkRedirects(user);
  if (redirect) {
    return redirect;
  }

  const onTryToLogin = (e) => {
    e.preventDefault();

    if (!formRef.current) {
      return
    }

    const username = formRef.current.username;
    const password = formRef.current.password;
    const usernameValue = username.value;
    const passwordValue = password.value;

    if (!usernameValue) {
      setErrors(er => ({ ...er, username: true }));
      return
    }
    if (!passwordValue) {
      setErrors(er => ({ ...er, password: true }));
      return
    }

    authorize(usernameValue, passwordValue)
      .then((response) => {
        const { error, ...user } = response;
        if (error) {
          username.value = '';
          password.value = '';
          const isNotFound = error === 404;
          setErrors(er => ({ ...er, login: isNotFound, server: !isNotFound }));
        } else {
          setUser(user);
        }
      })
  };

  const changeInput = (fieldName) => {
    return () => {
      setErrors(er => ({ ...er, [fieldName]: false, login: false, server: false }));
    }
  };

  return (
    <section className="loginSection" >
      <form ref={ formRef } className="loginForm" onSubmit={ onTryToLogin } >
        { errors.username && <span className="span-error" >Поле не заполнено</span> }
        <MyTextInput
          hasError={ errors.username }
          name="username"
          placeholder="Введите имя пользователя"
          onChange = { changeInput("username") }
        />
        { errors.password && <span className="span-error" >Поле не заполнено</span> }
        <MyTextInput
          hasError={ errors.password }
          name="password"
          type="password"
          placeholder="Введите пароль"
          onChange={ changeInput("password") }
        />
        <MyButton onClick={ onTryToLogin } >Войти</MyButton>
        <button className="buttonLink" >Забыли пароль</button>
        { errors.login && <span className="span-error" >Пользователь не найден</span> }
        { errors.server && <span className="span-error" >Сервер недоступен</span> }
      </form>
    </section>
  )
}

function checkRedirects(user) {
  if (!user) {
    return null
  }

  if (isAdmin(user) || isManager(user)) {
    return (
      <Redirect to={{ pathname: "/admin" }} />
    )
  }

  return (
    <Redirect to={{ pathname: "/" }} />
  )
}
