import superagent from 'superagent'

export async function getAddressSuggestions(query) {
    //todo: MOVE TOKEN TO ENV!!!
    const dadataApiKey = `01b914ab7772372707b6732086ab61f2cae5eca7`  
    const requestUrl = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`
    
    try {
        const response = await superagent
            .post(requestUrl)
            .set('Authorization', `Token ${dadataApiKey}`)
            .send({ query, count: 4 })
        
        console.log(response.body)

        return response.body
    }
    catch (e) {
        console.log('dadata request error', e)

        return { suggestions: [] }
    }
}