import React, { useState, useEffect, useRef } from 'react'
import { useParams, Link, Redirect, Prompt } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import AvatarEditor from 'react-avatar-editor'
import { Dots } from 'react-preloaders'
import includes from 'lodash/includes'

import usersApi from '../api/users'
import { logout } from '../api/auth'
import checkIfNewUser from '../utils/checkIfNew'

import MyTextInput from './MyTextInput'
import MyButton from './MyButton'

import '../styles/EditForm.css'

const requiredInputs = [
  'firstName',
  'lastName',
  'username',
]
// 'password',
//   'passwordAgain'

export default function User({ mainUser }) {
  const { userId } = useParams('')
  const [ loading, setLoading ] = useState(true);
  const [user, setUser] = useState(null)
  const [users, setUsers] = useState(null)
  const [isRedirectBack, setRedirectBack] = useState(false)
  const [isBlocking, setBlocker] = useState(false)
  const [errors, setErrors] = useState({
    lastName: false,
    firstName: false,
    username: false,
    password: false,
    passwordAgain: false,
    existedUsername: false,
    avatar: false,
    avatarSize: false,
    changePassword: false,
    changePasswordFailure: false
  })
  const [avatarImage, setAvatarImage] = useState(null)
  const [changePasswordSuccess, setSuccess] = useState(false)
  const [password, changePassword] = useState('');
  const formRef = useRef(null)
  const avatarRef = useRef(null)
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 550px)'
  });

  const isNewUser = checkIfNewUser(userId)

  let startValues = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    roles: [],
    avatar: ''
  }
  if (!isNewUser && user) {
    startValues = {
      ...startValues,
      ...user,
    };
  }

  const setPassword = (e) => {
    if (errors.password) {
      setErrors({
        ...errors,
        password: false
      })
    }
    changePassword(e.target.value);
  }

  const verifyPassword = (e) => {
    if (e.target.value !== password) {
      setErrors({
        ...errors,
        passwordAgain: true
      })
    } else {
      setErrors({
        ...errors,
        passwordAgain: false
      })
    }
  }

  const onChangeInput = (fieldName) => {
    return (e) => {
      if (!isNewUser) setBlocker(true);
      disableError(fieldName);
    }
  }

  const disableError = (fieldName) => {
    if (fieldName === 'user') {
      if (errors.username || errors.existedUsername) {
        setErrors({
          ...errors,
          username: false,
          existedUsername: false
        })
      }
    } else {
      setErrors({
        ...errors,
        [fieldName]: false
      })
    }
  }

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.returnValue = "Уйти без сохранения?";
    }

    window.addEventListener('beforeunload', onBeforeUnload)

    usersApi.getUsers()
      .then(setUsers);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (checkIfNewUser(userId)) {
      // создаём нового пользователя, его не нужно подгружать
      setLoading(false);
      return
    }

    usersApi.getUser(userId)
      .then(setUser)
      .then(() => setLoading(false))
      .catch((e) => {
        setLoading(false);
        console.error('ERROR get user', e.message)
      })
  }, [userId]);

  const onDeleteImage = (e) => {
    e.preventDefault();
    setAvatarImage(null);
    if (errors.avatarSize || errors.avatar) {
      setErrors(er => ({ ...er, avatar: false, avatarSize: false }))
    }
    setUser({
      ...user,
      avatar: null
    });
  }

  const handleNewImage = (e) => {
    setAvatarImage(e.target.files[0])
  }

  const onImageLoadFailure = (e) => {
    setAvatarImage(null);
    setErrors(er => ({ ...er, avatar: true }))
  }

  const onChangePassword = (e) => {
    e.preventDefault();

    const oldPassword = formRef.current.oldPassword.value;
    const newPassword = formRef.current.password.value;
    const newPasswordAgain = formRef.current.passwordAgain.value;

    if (!oldPassword || !newPassword || !newPasswordAgain) {
      setErrors(er => ({ ...er, changePassword: true }));
      return;
    }
    if (newPassword !== newPasswordAgain) {
      setErrors(er => ({ ...er, passwordAgain: true }));
      return;
    }
    usersApi.changePassword(userId, oldPassword, newPassword)
      .then(res => {console.log(res); return res})
      .then(res => {
        console.log(res.id)
        if (res.id) setSuccess(true)
        else throw new Error(res.error)
      })
      .catch((e) => setErrors(er => ({ ...er, changePasswordFailure: true })))
      .then(() => {
        formRef.current.oldPassword.value = '';
        formRef.current.password.value = '';
        formRef.current.passwordAgain.value = '';
      })
  }

  const onTryToLogin = (e) => {
    e.preventDefault()

    if (!formRef.current) {
      return
    }

    const userToSave = {
      lastName: formRef.current.lastName.value,
      firstName: formRef.current.firstName.value,
      email: formRef.current.email.value,
      username: formRef.current.username.value,
      roles: [],
      avatar: (avatarRef.current && avatarRef.current.getImageScaledToCanvas().toDataURL()) || startValues.avatar
    }

    if (isNewUser) {
      userToSave.password = formRef.current.password.value
      userToSave.passwordAgain = formRef.current.password.value
    }

    const checkboxes = formRef.current.roles
    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        userToSave.roles.push(checkbox.value)
      }
    }

    // userToSave содержит все данные, которые надо проверять кроме passwordAgain

    // проверка ошибок
    const newErrors = { ...errors }
    for (const name of requiredInputs) {
      newErrors[name] = (userToSave[name] === '')
    }

    newErrors['existedUsername'] = (isNewUser && users.some(({ username }) => username === userToSave.username));

    if (isNewUser) {
      userToSave.password = formRef.current.password.value.toString()
      if (!userToSave.password) {
        newErrors.password = true
      }

      const passCheck = formRef.current.passwordAgain.value.toString()
      newErrors.passwordAgain = (userToSave.password.localeCompare(passCheck) !== 0);
    }
    const checkError = (Object.values(newErrors).some(error => error === true));

    setErrors(newErrors)

    if (checkError) {
      console.log("HAS ERRORS", newErrors)
      return false
    }

    if (isNewUser) {
      usersApi.createUser(userToSave)
      .then(() => {
        setRedirectBack(true)
      })
      .catch((e) => {
        console.error('ERROR create user', e.message, e.status);
        if (e.status === 413) setErrors(er => ({ ...er, avatarSize: true }))
      })
    } else {
      usersApi.updateUser(userId, userToSave)
      .then(() => {
        setRedirectBack(true)
      })
      .catch((e) => {
        console.error('ERROR update user', e.message, e.status);
        if (e.status === 413) setErrors(er => ({ ...er, avatarSize: true }))
      })
    }
  }

  let breadCrumbsCaption = (isNewUser || !user) ?
    'Новый пользователь' : `${user.lastName} ${user.firstName}`

  if (isRedirectBack) {
    return <Redirect to={{ pathname: "/admin/users" }} />
  }

  return (
    <section>
      <nav className="mainNavMenu" >
        { isMobileDevice ? (
          <section className="mainNav_mobileTitle" >
            { isNewUser ? "Новый пользователь" : user && `${user.firstName} ${ user.lastName }` }
          </section>
          ) : (
          <section className="submitSection" >
            <MyButton onClick={ onTryToLogin } mod="myButton_big" >{ isNewUser ? "Создать" : "Сохранить" }</MyButton>
          </section>
        )}
        <ul className="mainNavList mainNavList__right mainNavList__rightResponsive" >
          { mainUser.avatar &&
          <li className="mainNavListItem__avatar" >
            <img src={ mainUser.avatar } alt="user_icon" />
          </li> }
          { !isMobileDevice && <li className="mainNavListItem__right" >{ mainUser.firstName } { mainUser.lastName }</li> }
          <li className="mainNavListItem__right mainNavListItem__rightPartial" ><button className="mainButton__red" onClick={ logout } >Выход</button></li>
        </ul>
      </nav>
      <nav className="breadCrumbs" >
        <Link className="breadCrumbsLink" to="/admin/users" >{ isMobileDevice ? "< Пользователи" : "Пользователи /" }</Link> { !isMobileDevice && breadCrumbsCaption }
      </nav>
      <Prompt
        when={ isBlocking }
        message="Уйти без сохранения?"
      />
      <form ref={ formRef } onSubmit={ onTryToLogin }>
        <section className="editForm" >
          <section className="editForm_block" >
            <p className="editFormField userField" >
              <label className="editFormField_label" htmlFor="lastName" >Фамилия</label>
              <MyTextInput
                hasError={ errors.lastName }
                id="lastName"
                name="lastName"
                placeholder="Укажите Фамилию пользователя"
                defaultValue={ startValues.lastName }
                onChange={ onChangeInput('lastName') }
              />
              { errors.lastName && <span className="span-error" >Поле не заполнено</span> }
            </p>
            <p className="editFormField userField" >
              <label className="editFormField_label" htmlFor="firstName" >Имя</label>
              <MyTextInput
                hasError={ errors.firstName }
                id="firstName"
                name="firstName"
                placeholder="Укажите имя пользователя"
                defaultValue={ startValues.firstName }
                onChange={ onChangeInput('firstName') }
              />
              { errors.firstName && <span className="span-error" >Поле не заполнено</span> }
            </p>
          </section>
          <section className="editForm_block" >
            <p className="editFormField userField" >
              <label className="editFormField_label" htmlFor="email">Email</label>
              <MyTextInput
                type="email"
                id="email"
                name="email"
                placeholder="Укажите email пользователя"
                defaultValue={ startValues.email }
              />
            </p>
            <p className="editFormField userField" >
              <label className="editFormField_label" htmlFor="username" >Login</label>
              <MyTextInput
                hasError={ errors.username || errors.existedUsername }
                id="username"
                name="username"
                placeholder="Укажите login"
                defaultValue={ startValues.username }
                onChange={ onChangeInput('user') }
              />
              { errors.username && <span className="span-error" >Поле не заполнено</span> }
              { errors.existedUsername && <span className="span-error" >Пользователь с таким именем уже существует</span> }
            </p>
          </section>
          <section className="editForm_block" >
            <div className="editFormField userField" >
              <span className="editFormField_label" >Аватар</span>
              { startValues.avatar ? (
                <div className="editAvatar" >
                  <div onClick={ onDeleteImage } className="editAvatar_delete" />
                  <img src={ startValues.avatar } alt="Аватар" className="editAvatar_image" />
                </div>
              ) : ( avatarImage ? (
                <div className="editAvatar" >
                  <div onClick={ onDeleteImage } className="editAvatar_delete" />
                  <AvatarEditor
                    ref={avatarRef}
                    width={164}
                    height={164}
                    scale={1}
                    borderRadius={26}
                    color={[255, 255, 255, 0.6]}
                    image={avatarImage}
                    crossOrigin="anonymous"
                    onLoadFailure={onImageLoadFailure}
                  />
                  <br />
                  { errors.avatarSize && <span className="span-error" >Не удалось загрузить фото, размер слишком велик</span> }
                </div>
                ) : (
                  <div>
                    <input type="file" id="avatar_file" onChange={ handleNewImage } />
                    <label htmlFor="avatar_file" className={ `editFormField_avatarLabelBlock ${ errors.avatar && "error" }` } >
                      <div className="editAvatar_uploadIcon uploadIcon" />
                      <span className="editFormField_avatarLabel" >загрузить фото</span>
                    </label>
                    { errors.avatar && <span className="span-error" >Не удалось загрузить фото, попробуйте еще раз</span> }
                  </div>
                )
              )}
            </div>
            <p className="editFormField userField" >
              <span className="editFormField_label" >Роли</span>
              <input
                type="checkbox"
                name="roles"
                value="admin"
                id="role_admin"
                defaultChecked={ includes(startValues.roles, 'admin') ? 'on' : null }
              />
              <label className="editFormField_inputLabel" htmlFor="role_admin" >
                <span className="editFormField_checkbox" />
                Администратор
              </label>
              <input
                type="checkbox"
                name="roles"
                value="manager"
                id="role_manager"
                defaultChecked={ includes(startValues.roles, 'manager') ? 'on' : null }
              />
              <label className="editFormField_inputLabel" htmlFor="role_manager" >
                <span className="editFormField_checkbox" />
                Менеджер релизов
              </label>
              <input
                type="checkbox"
                name="roles"
                id="role_kioskMaster"
                value="kioskMaster"
                defaultChecked={ includes(startValues.roles, 'kioskMaster') ? 'on' : null }
              />
              <label className="editFormField_inputLabel" htmlFor="role_kioskMaster" >
                <span className="editFormField_checkbox" />
                Настройщик киосков
              </label>
              <input
                type="checkbox"
                name="roles"
                id="role_kioskOperator"
                value="kioskOperator"
                defaultChecked={ includes(startValues.roles, 'kioskOperator') ? 'on' : null }
              />
              <label className="editFormField_inputLabel" htmlFor="role_kioskOperator" >
                <span className="editFormField_checkbox" />
                Оператор терминала
              </label>
            </p>
          </section>
        </section>
        <section className="editForm" >
          <section className="editForm_block" >
            <p className="editFormField userField" >
              <label className="editFormField_label" htmlFor="password" >{ isNewUser ? "Пароль" : "Смена пароля" }</label>
              { !isNewUser && (
                <MyTextInput
                  hasError={ errors.changePassword || errors.changePasswordFailure }
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="Старый пароль"
                  onChange={ onChangeInput('changePasswordFailure') }
                />
              )}
              <MyTextInput
                hasError={ errors.password || errors.passwordAgain || errors.changePassword }
                type="password"
                id="password"
                name="password"
                placeholder={ isNewUser ? "Введите пароль" : "Новый пароль" }
                onChange={ setPassword }
              />
              <MyTextInput
                hasError={ errors.passwordAgain || errors.changePassword }
                type="password"
                id="passwordAgain"
                name="passwordAgain"
                placeholder="Подтвердите пароль"
                onChange={ verifyPassword }
              />
              { changePasswordSuccess && <span className="span-success shiftLeft" >Пароль успешно изменен</span> }
              { errors.passwordAgain && <span className="span-error" >Пароль и подтверждение пароля не совпадают</span> }
              { errors.changePasswordFailure && <span className="span-error" >Введен неверный пароль</span> }
              { errors.changePassword && <span className="span-error" >Для изменения пароля необходимо заполнить все поля</span> }
              { errors.password && <span className="span-error" >Поле не заполнено</span> }
            </p>
            { !isNewUser && (
              <p className="editFormField userField" >
                { !isMobileDevice && <label className="editFormField_label white" >Кнопка</label> }
                <MyButton onClick={ onChangePassword } mod="myButton_white changePassword_button" >Изменить пароль</MyButton>
              </p>
            )}
          </section>
          { isMobileDevice &&
            <section className="submitSection" >
              <MyButton onClick={ onTryToLogin } mod="myButton_big" >{ isNewUser ? "Создать" : "Сохранить" }</MyButton>
            </section>
          }
        </section>
      </form>
      <Dots customLoading={ loading } background="blur" color="#5e8adf" time={0} />
    </section>
  )
}
