import isString from 'lodash/isString'

export default function wrapModifications (modification) {
  if (!modification) {
    return {}
  }

  if (isString(modification)) {
    return { [modification]: true }
  }
  return modification
}