import React, { useState, useRef, useEffect } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import AvatarEditor from 'react-avatar-editor'
import { Dots } from 'react-preloaders'
import moment from 'moment'

import applicationApi from '../api/applications'
import assemblyApi from '../api/assembly'
import checkIfNewApp from '../utils/checkIfNew'
import { logout } from '../api/auth'

import MyTextInput from './MyTextInput'
import MyButton from './MyButton'
import Toggle from './Toggle'

import '../styles/EditForm.css'
import '../styles/Assembly.css'

const requiredFields = ['name', 'description', 'strId'];

function AssemblyItem({ assembly }) {
  const fullDescriptionRef = useRef(null);
  const isTabletDevice = useMediaQuery({
    query: '(max-device-width: 1000px)'
  });
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 768px)'
  });

  const showFullDescription = () => {
    if (isMobileDevice) return;
    fullDescriptionRef.current.style.display = "block"
  };
  const hideFullDescription = () => {
    fullDescriptionRef.current.style.display = "none"
  };

  return (
    <li className="assemblyItem_listItem" >
      { isMobileDevice ? (
        <section className="listItem_section listItem_sectionColumn" >
          <div className="listItem_sectionColumn_part" >
            <span className="section_tableHeader" >Сборка</span>
            <span className="linkCaption" >{ assembly.branch.toUpperCase() }</span>
          </div>
          <div className="listItem_sectionColumn_part" >
            <span className="section_tableHeader" >Версия</span>
            <span className="linkCaption" >{ assembly.version }</span>
          </div>
          <div className="listItem_sectionColumn_part" >
            <span className="section_tableHeader" >Дата сборки</span>
            <span className="linkCaption" >{ moment(assembly.createdAt).format('DD.MM.YYYY HH:mm') }</span>
          </div>
          <div className="listItem_sectionColumn_part" >
            <span className="section_tableHeader" >Кол-во установок</span>
            <span className="linkCaption" >{ assembly.setupCounter || 0 }</span>
          </div>
          <div className="listItem_sectionColumn_part" >
            <span className="section_tableHeader" >Комментарий</span>
            <span className="linkCaption assembly-description" >{ assembly.description }</span>
          </div>
          <div className="listItem_sectionColumn_part" >
            <span className="section_tableHeader" >Скачать</span>
            <span className="linkCaption" >
              <a
                href={ `/download/assembly/${assembly.strId}` }
                download={ `assembly_v${ assembly.version }.zip` }
                className="assemblyItem_download"
              >
                <span>{ assembly.fileSize || 0 } MB</span>
                <span className="icon_download assembly" />
              </a>
            </span>
          </div>
        </section>
      ) : (
      <section className="listItem_section" >
        <span className="linkCaption" >{ assembly.branch.toUpperCase() }</span>
        <span className="linkCaption assembly-version" >{ assembly.version }</span>
        <span className="linkCaption assembly-date" >{ moment(assembly.createdAt).format(isTabletDevice ? 'DD.MM.YYYY' : 'DD.MM.YYYY, HH:mm') }</span>
        <span className="linkCaption assembly-setup" >{ assembly.setupCounter || 0 }</span>
        <span
          className="linkCaption assembly-description"
          onMouseOver={ showFullDescription }
          onMouseOut={ hideFullDescription }
        >
          { assembly.description }
          </span>
        <span className="assembly-fullDescription" ref={ fullDescriptionRef } >{ assembly.description }</span>
        <span className="linkCaption assembly-download" >
          <a
            href={ `/download/assembly/${assembly.strId}` }
            download={ `assembly_v${ assembly.version }.zip` }
            className="assemblyItem_download"
          >
            <span>{ assembly.fileSize || 0 } MB</span>
            <span className="icon_download assembly" />
          </a>
        </span>
      </section>
      )}
    </li>
  )
}

export default function Application({ mainUser }) {
  const { appId } = useParams('');
  const formRef = useRef(null);
  const avatarRef = useRef(null);
  const [ isNewApp, setIsNewApp ] = useState(true);
  const [ application, setApplication ] = useState(null);
  const [ updateApplicationInfo, setUpdateApplicationInfo ] = useState(false);
  const [ assemblies, setAssemblies ] = useState([]);
  const [ avatarImage, setAvatarImage ] = useState(null);
  const [ isRedirectBack, setRedirectBack ] = useState(false);
  const [ checked, setIfChecked ] = useState(false);
  const [ apps, setApps ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ errors, setErrors ] = useState({
    name: false,
    description: false,
    strId: false,
    strIdUnique: false,
    strIdPattern: false,
    avatarSize: false
  });
  const isTabletDevice = useMediaQuery({
    query: '(max-device-width: 768px)'
  });
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 550px)'
  });

  useEffect(() => {
    applicationApi
      .getApplicationList()
      .then(setApps);

    const onBeforeUnload = (e) => {
      e.returnValue = "Уйти без сохранения?";
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, []);

  useEffect(() => {
    if (!checkIfNewApp(appId)) {
      applicationApi.getApplication(appId)
        .then(app => {
          setIfChecked(app.freeDownload);
          return app
        })
        .then(setApplication)
        .then(() => setLoading(false))
        .catch(e => {
          setLoading(false);
          console.error('ERROR get application', e.message)
        })
    }

    setLoading(false);
    setIsNewApp(checkIfNewApp(appId))
  }, [appId, mainUser]);

  useEffect(() => {
    if (application) {
      Promise.all([
        assemblyApi.getAssemblyList(appId, 'canary'),
        assemblyApi.getAssemblyList(appId, 'production'),
        assemblyApi.getAssemblyList(appId, 'test'),
      ]).then(([canary, production, test]) => {
        const arr = [...canary, ...production, ...test]
          .sort((a, b) => (a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0));
        if (assemblies.length !== arr.length) setAssemblies(arr)
      })
        .then(() => setLoading(false))
        .catch(e => {
          setLoading(false);
          console.error('ERROR get assemblies', e.message)
        })
    }
  }, [application]);

  useEffect(() => {
    if (assemblies.length) {
      applicationApi.getApplication(appId)
        .then(app => {
          setApplication(app)
        })
        .then(() => setLoading(false))
    }
  }, [assemblies.length]);

  const showPopupApplicationUpdateInfo = () => {
    setUpdateApplicationInfo(true);
    setTimeout(closeUpdateApplicationInfo, 5000)
  };

  const closeUpdateApplicationInfo = () => {
    setUpdateApplicationInfo(false)
  };

  const onChangeInput = (fieldName) => {
    return (e) => {
      if (errors[fieldName]) disableError(fieldName);
    }
  };

  const disableError = (fieldName) => {
    setErrors({
      ...errors,
      [fieldName]: false
    })
  };

  const onChangeStrId = (e) => {
    const res = e.target.value.match(/^[-_0-9a-zA-Z]+$/);
    if (!res && errors.strId)
      setErrors(e => ({...e, strIdPattern: true, strId: false}));
    else if (!res)
      setErrors(e => ({...e, strIdPattern: true}));
    else {
      onChangeInput('strId')(e);
      onChangeInput('strIdPattern')(e);
      onChangeInput('strIdUnique')(e);
    }
  };

  const onCheckedChange = () => {
    setIfChecked(ch => !ch)
  };

  const onDeleteImage = (e) => {
    e.preventDefault();
    setAvatarImage(null);
  };

  const handleNewImage = (e) => {
    setAvatarImage(e.target.files[0])
  };

  const onSaveApp = (e) => {
    e.preventDefault();

    const saveData = {
      name: formRef.current.name.value,
      description: formRef.current.description.value,
      strId: (formRef.current.strId && formRef.current.strId.value) || (application && application.strId) || "",
      freeDownload: formRef.current.freeDownload.checked,
      avatar: (avatarRef.current && avatarRef.current.getImageScaledToCanvas().toDataURL()) || (application && application.avatar) || null
    };

    let newErr = {};
    for (const field of requiredFields) {
      newErr[field] = (saveData[field] === '')
    }
    newErr.strIdUnique = (isNewApp && apps.some(app => app.strId === saveData.strId));
    if (Object.keys(newErr).some(er => newErr[er])) {
      setErrors(er => ({...er, ...newErr}));
      return;
    }

    if (isNewApp) {
      applicationApi
        .createApplication(saveData)
        .then(res => setRedirectBack(true))
        .catch(e => {
          console.error('ERROR create application', e.message, e.status);
          if (e.status === 413) setErrors(er => ({ ...er, avatarSize: true }))
        })
    } else {
      applicationApi
        .updateApplication(appId, saveData)
        .then(applicationApi.getApplication(appId))
        .then(setApplication)
        .then(() => showPopupApplicationUpdateInfo())
        .catch(e => {
          console.error('ERROR create application', e.message, e.status);
          if (e.status === 413) setErrors(er => ({ ...er, avatarSize: true }))
        })
    }
  };

  if (isRedirectBack) {
    return <Redirect to={{ pathname: "/admin" }} />
  }

  return (
    <section>
      <nav className="mainNavMenu" >
        { isMobileDevice ? (
          <section className="mainNav_mobileTitle" >
            { isNewApp ? "Новое приложение" : application && application.name }
          </section>
          ) : (
          <section className="submitSection" >
            <MyButton onClick={ onSaveApp } mod="myButton_big" >{ isNewApp ? "Создать" : "Сохранить" }</MyButton>
          </section>
          )}
        <ul className="mainNavList mainNavList__right mainNavList__rightResponsive" >
          { mainUser.avatar &&
          <li className="mainNavListItem__avatar" >
            <img src={ mainUser.avatar } alt="user_icon" />
          </li> }
          { !isMobileDevice && <li className="mainNavListItem__right" >{ mainUser.firstName } { mainUser.lastName }</li> }
          <li className="mainNavListItem__right mainNavListItem__rightPartial" ><button className="mainButton__red" onClick={ logout } >Выход</button></li>
        </ul>
      </nav>
      <nav className="breadCrumbs" >
        <Link className="breadCrumbsLink" to="/admin" >{ isMobileDevice ? "< Приложения" : "Приложения /" }</Link> { !isMobileDevice && isNewApp ? "Новое приложение" : !isMobileDevice && application && application.name }
      </nav>
      { updateApplicationInfo && (
        <div className="appUpdatePopup" >
          Информация о приложении обновлена
          <a className="appUpdatePopupClose" onClick={ closeUpdateApplicationInfo } >&#9587;</a>
        </div>
      )}
      <form ref={ formRef } onSubmit={ onSaveApp } >
        <section className="editForm" >
          <div className="editFormField appField" >
            <label className="editFormField_label" htmlFor="name" >Название приложения</label>
            <MyTextInput
              hasError={ errors.name }
              id="name"
              name="name"
              placeholder="Введите название приложения..."
              defaultValue={ application && application.name }
              onKeyUp={ onChangeInput('name') }
            />
            { errors.name && <span className="span-error" >Поле не заполнено</span> }
          </div>
          <div className="editFormField appField" >
            <label className="editFormField_label" htmlFor="description" >Текстовое описание</label>
            <textarea
              className={`appSection_textarea ${ errors.description && "error" }`}
              rows="4"
              name="description"
              placeholder="Введите описание приложения"
              defaultValue={ application && application.description }
              onKeyUp={ onChangeInput('description') }
            />
            { errors.description && <span className="span-error" >Поле не заполнено</span> }
          </div>
          <div className="editFormField appField" >
            <label className="editFormField_label" htmlFor="strId" >Уникальный идентификатор</label>
            { isNewApp ?
            <MyTextInput
              hasError={ errors.strId || errors.strIdUnique || errors.strIdPattern }
              id="strId"
              name="strId"
              pattern="^[-_0-9a-zA-Z]+$"
              placeholder="Введите уникальный идентификатор приложения"
              onKeyUp={ onChangeStrId }
            /> : <span>{ application && application.strId }</span> }
            { errors.strId && <span className="span-error" >Поле не заполнено</span> }
            { errors.strIdUnique && <span className="span-error" >Указанный идентификатор уже существует</span> }
            { errors.strIdPattern && <span className="span-error" >Допустимые символы: -_0-9a-zA-Z</span> }
          </div>
          <div className="editFormField" >
            <span className="editFormField_label" >Иконка приложения</span>
            { application && application.avatar ? (
              <div className="editAvatar" >
                <div onClick={ onDeleteImage } className="editAvatar_delete" />
                <img src={ application.avatar } alt="Аватар" className="editAvatar_image" />
              </div>
            ) : ( avatarImage ? (
              <div className="editAvatar" >
                <div onClick={ onDeleteImage } className="editAvatar_delete" />
                <AvatarEditor
                  ref={avatarRef}
                  width={164}
                  height={164}
                  scale={1}
                  borderRadius={26}
                  color={[255, 255, 255, 0.6]}
                  image={avatarImage}
                  crossOrigin="anonymous"
                />
                <br />
                { errors.avatarSize && <span className="span-error" >Не удалось загрузить фото, размер слишком велик</span> }
              </div>
              ) : (
              <div>
                <input type="file" id="avatar_file" onChange={ handleNewImage } />
                <label htmlFor="avatar_file" className={ `editFormField_avatarLabelBlock ${ errors.avatar && "error" }` } >
                  <div className="editAvatar_uploadIcon uploadIcon" />
                  <span className="editFormField_avatarLabel" >загрузить фото</span>
                </label>
              </div>
              ))
            }
          </div>
          <div className="editFormField" >
            <div className="editFormField_choiceBlock" >
              <Toggle
                className="editCheck_toggle"
                name="freeDownload"
                checked={ checked }
                onChange={ onCheckedChange }
              />
              <label className="editFormField_choiceBlock_title" htmlFor="freeDownload" >Свободная загрузка</label>
            </div>
          </div>
          { !isNewApp && (
            <section>
              { assemblies && assemblies.length > 0 && (
                <div className="editFormField" >
                  <ul className="genericItemList" >
                    { !isTabletDevice &&
                    <li className="assemblyItem_listHeader" >
                      <span className="section_tableHeader" >Сборка</span>
                      <span className="section_tableHeader assembly-version" >Версия</span>
                      <span className="section_tableHeader assembly-date" >Дата сборки версии</span>
                      <span className="section_tableHeader assembly-setup" >Кол-во установок</span>
                      <span className="section_tableHeader assembly-description" >Комментарий</span>
                      <span className="section_tableHeader assembly-download" >Скачать</span>
                    </li> }
                    { assemblies.map(assembly => <AssemblyItem key={ assembly.id } assembly={ assembly } />) }
                  </ul>
                </div>
              )}
              <div className="editFormField" >
                <Link to={ `/admin/assembly/${appId}` } >
                  <MyButton className="myButton myButton_white changePassword_button" >
                    Добавить сборку
                    <span className="icon_add" />
                  </MyButton>
                </Link>
              </div>
            </section>
          )}
          { isMobileDevice && <section className="submitSection" >
            <MyButton onClick={ onSaveApp } mod="myButton_big" >{ isNewApp ? "Создать" : "Сохранить" }</MyButton>
          </section> }
        </section>
      </form>
      <Dots customLoading={loading} background="blur" color="#5e8adf" time={0} />
    </section>
  )
}
