import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Dots } from 'react-preloaders'

import usersApi from '../api/users'
import DeletePopup from './DeletePopup'

import '../styles/User.css'

function UserItem({ user, onDelete }) {
  const [showPopup, setShowPopup] = useState(false);

  const onClickDelete = () => {
    usersApi.deleteUser(user.id)
      .then(status => status === 200 && onDelete(user.id))
  };

  const showDeletePopup = () => {
    setShowPopup(true)
  };
  const hideDeletePopup = () => {
    setShowPopup(false)
  };

  return (
    <li className="genericItemList_Item addUser" >
      <div className="linkImage" >
        { user.avatar ? <img src={ user.avatar } alt="avatar" /> : <div><div className="icon_defaultUser" /></div> }
      </div>
      <span className="linkCaption" >{ user.lastName } { user.firstName }</span>
      <span className="rightPanel" >
        <Link
          to={ `/admin/users/${user.id}` }
          className="rightPanel_config"
        >
          <div className="icon_config" />
        </Link>
        <a onClick={ showDeletePopup } className="rightPanel_delete" >
          <div className="icon_delete" />
        </a>
      </span>
      { showPopup && <DeletePopup
        type="пользователя"
        name={ `${user.firstName} ${user.lastName}` }
        onDelete={ onClickDelete }
        onCancel={ hideDeletePopup }
      /> }
    </li>
  )
}

export default function Users() {
  const [ loading, setLoading ] = useState(true);
  const [users, setUsers] = useState([]);
  const [deletedUser, setDeletedUser] = useState(null);
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 420px)'
  });

  useEffect(() => {
    setLoading(true)
    usersApi.getUsers()
      .then(setUsers)
      .then(() => setLoading(false))
      .catch((e) => {
        setLoading(false);
        console.error('ERROR get user list', e.message)
      })
  }, [deletedUser]);

  return (
    <section className="pageSection" >
      { !isMobileDevice && <h1 className="sectionHeader" >Пользователи</h1> }
      <ul className="genericItemList userList" >
        { users && users.length > 0 && users.map((user) => <UserItem key={ user.id } user={ user } onDelete={ setDeletedUser } /> ) }
        <li className="genericItemList_Item addUser" >
          <Link className="genericItemList_LinkPrimary" to="/admin/users/add" >
            <div className="genericItemList_LinkPrimary_block" >
              <span className="icon_addUser" />
              <span className="linkCaption blue" >Добавить пользователя</span>
            </div>
          </Link>
        </li>
      </ul>
      <Dots customLoading={ loading } background="blur" color="#5e8adf" time={0} />
    </section>
  )
}
