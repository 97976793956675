import React, { useEffect, useRef, useState } from 'react';
import { defaults as defaultControls, Zoom } from 'ol/control';
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM';
import View from 'ol/View'
import VectorLayer from 'ol/layer/Vector'
import { transform, fromLonLat } from 'ol/proj'
import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import '../styles/Map.css'
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';

const initialMapCenter = [60.583332, 56.833332]

const pointsSource = new VectorSource();
const pointsLayer = new VectorLayer({
    source: pointsSource,
    style: function (feature) {
        const { scale, isSelected } = feature.getProperties()
        const iconPath = '/assets/map-marker.png'

        console.log(feature)

        return new Style({
            fill: new Fill({
                color: [0, 0, 255, 0.6],
            }),
            stroke: new Stroke({
                width: 3,
                color: [255, 0, 0, 1],
            }),
            image: new Icon({
                scale: 0.5,
                src: iconPath
            })
        })
    }
})

export default function({
    pointList,
    onPointClick, 
    onEmptyClick
}) {    
    const mapElement = useRef();    
    const [map, setMap] = useState(null)
    
    const mapRef = useRef()
    mapRef.current = map

    const handleMapClick = (event) => {
        const featuresAtPixel = []

        mapRef.current.forEachFeatureAtPixel(event.pixel, (feature) => featuresAtPixel.push(feature))

        if (featuresAtPixel.length > 0) {
            onPointClick(featuresAtPixel[0].values_.properties)
        }
        else {
            onEmptyClick()
        }
    }

    useEffect(() => {
        if (!map) {
            return
        }

        map.on('click', handleMapClick)

        return () => {
            map.un('click', handleMapClick)
        }
    }, [map])
    

    useEffect(() => {
        const initialMap = new Map({
            controls: [
                new Zoom()
            ],
            target: mapElement.current,
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
                pointsLayer
                //initalFeaturesLayer,
                //drawingVector,
                //pointsLayer,
                //polygonVector
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: fromLonLat(initialMapCenter),
                zoom: 10
            }),
        });     


        if (map) {
            map.dispose() //not sure if it is correct
        }

        setMap(initialMap)

        return () => {
            if (initialMap) {
                initialMap.dispose()
            }

            if (map) {
                map.dispose()
            }
        }
    }, [])

    useEffect(() => {
        pointsSource.clear()

        const usedCoordinates = {}

        for (let pointData of pointList) {
            //no location (ex: geodata query failed) - just skip
            if (!pointData.location) {
                continue
            }

            let { geo_lat: latitude, geo_lon: longitude } = pointData.location

            if (!latitude || !longitude) {
                throw new Error('no lat/lng provided')
            }            

            while (usedCoordinates[longitude]) { //pointlist contains equal coordinates, slightly move next
                const longitudeFloat = parseFloat(longitude) + 0.00001

                longitude = longitudeFloat.toString()
            }

            pointsSource.addFeature(new Feature({
                geometry: new Point(fromLonLat([longitude, latitude])),
                properties: pointData
            }))

            usedCoordinates[longitude] = latitude
        }

        console.log(usedCoordinates)
    }, [map, pointList])

    return (
        <div ref={mapElement} className={`map`} />
    )
}