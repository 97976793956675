import React from 'react'
import { Redirect, Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'

import AdminMainMenu from './AdminMainMenu'
import Applications from './Applications'
import Application from './Application'
import Users from './Users'
import User from './User'
import Assembly from './Assembly'
import StatisticsMain from './StatistiicsMain'
import StatisticsPage from './StatisticsPage'
import checkUser from './checkUser'
import CreateLicense from './CreateLicense';
import LicenseCodesPage from './activations'

import { useUser } from '../api/auth'
import { isAdmin, isKioskMaster, isKioskOperator, isManager } from '../utils/permissions'
import Terminals from './Terminals'
import Dialogs from "./Dialogs";

export default function Admin() {
  const user = useUser();

  const { path } = useRouteMatch();
  const history = useHistory();

  const redirect = checkRedirects(user, history.location.pathname);
  if (redirect) {
    return redirect
  }

  return (
    <Switch>
      <Route exact path={ `${ path }` } >
        <section className="page" >
          <AdminMainMenu />
          <Applications mainUser={ user } />
        </section>
      </Route>
      <Route path={ `${path}/statistics/:appId` }>
        <section className="page" >
          <StatisticsPage mainUser={ user } />
        </section>
      </Route>
      <Route path={ `${path}/statistics` }>
        <section className="page" >
          <AdminMainMenu />
          <StatisticsMain />
        </section>
      </Route>
      <Route path={ `${ path }/applications/:appId` }>
        <section className="page" >
          <Application mainUser={ user } />
        </section>
      </Route>
      <Route exact path={ `${path}/users` }>
        <section className="page" >
          <AdminMainMenu />
          <Users />
        </section>
      </Route>
      <Route path={ `${path}/users/:userId` }>
        <section className="page" >
          <User mainUser={ user } />
        </section>
      </Route>
      <Route path={ `${path}/assembly/:appId` }>
        <section className="page" >
          <Assembly mainUser={ user } />
        </section>
      </Route>
      <Route path={ `${path}/create-license`}>
        <section className="page" >
          <AdminMainMenu />
          <CreateLicense mainUser={ user } />
        </section>
      </Route>
      <Route path={ `${path}/license-codes`}>
        <section className="page" >
          <AdminMainMenu />
          <LicenseCodesPage mainUser={ user } />
        </section>
      </Route>
      <Route path={`${path}/terminals`}>
        <section className="page">
          <AdminMainMenu />
          <Terminals />
        </section>
      </Route>

      <Route path={`${path}/dialogs`}>
        <section className="page">
          <AdminMainMenu />
          <Dialogs />
        </section>
      </Route>
    </Switch>
  )
}

function checkRedirects(user, path) {
  const redirect = checkUser(user);
  if (redirect) {
    return redirect
  }

  if ((!isAdmin(user) && !isManager(user) && !isKioskOperator(user)) ||
    (isManager(user) && path === '/admin/users')) {
    return (
      <Redirect to={{ pathname: "/admin" }}
      />
    )
  }

  return null
}
