import React from 'react'

export default function Toggle({ name, checked, className, onChange }) {
  const _onChange = (e) => {
    if (onChange) {
      onChange(e)
    }
  };

  return (
    <span className={className}>
      <input
        className="tgl tgl-light"
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={_onChange}
      />
      <label className="tgl-btn" htmlFor={name} />
    </span>
  )
}
