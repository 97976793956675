import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Dots } from 'react-preloaders'
import moment from 'moment'
import 'moment/locale/ru'

import appApi from '../api/applications'
import { logout } from '../api/auth'

import AdminMainMenu from './AdminMainMenu'
import AssemblyTable from './StatisticsAssemblyTable'
import SetupUpdateChart from './StatisticsSetupUpdateChart'

import '../styles/Statistics.css'
import '../styles/Assembly.css'

moment.locale('ru');

export default function ({ mainUser }) {
  const { appId } = useParams();
  const [ loading, setLoading ] = useState(true);
  const [ app, setCurrent ] = useState(null);
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 550px)'
  });

  useEffect(() => {
    appApi.getApplication(appId)
      .then(setCurrent)
      .then(() => setLoading(false));
  }, [appId]);

  return (
    app && (
      <section >
        { isMobileDevice ? (
          <nav className="mainNavMenu" >
            <section className="mainNav_mobileTitle" >
              { app && app.name }
            </section>
            <ul className="mainNavList mainNavList__right mainNavList__rightResponsive" >
              { mainUser.avatar &&
              <li className="mainNavListItem__avatar" >
                <img src={ mainUser.avatar } alt="user_icon" />
              </li> }
              { !isMobileDevice && <li className="mainNavListItem__right" >{ mainUser.firstName } { mainUser.lastName }</li> }
              <li className="mainNavListItem__right mainNavListItem__rightPartial" ><button className="mainButton__red" onClick={ logout } >Выход</button></li>
            </ul>
          </nav>
        ) : (
          <AdminMainMenu />
        )}
        <nav className="breadCrumbs" >
          <Link className="breadCrumbsLink" to="/admin/statistics" >{ isMobileDevice ? "< Статистика" : "Статистика /" }</Link> { !isMobileDevice && app.name }
        </nav>
        <section className="pageSection statPage" >
          <header className="section_tableHeader statPage_header" >Скачивания сборки приложения</header>
          <AssemblyTable appId={ app.strId } />
          <header className="section_tableHeader statPage_header" >Количество установок и обновлений приложения</header>
          <SetupUpdateChart appId={ app.strId } />
        </section>
        <Dots customLoading={ loading } background="blur" color="#5e8adf" time={0} />
      </section>
    )
  )
}