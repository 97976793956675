// возвращает cookie с именем name, если есть, если нет, то undefined
const getCookie = (name) => {
  try {
    const matches = document.cookie.match(new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
    ))

    const result = matches ? decodeURIComponent(matches[1]) : undefined
    if (result === 'true') {
      return true
    }
    if (result === 'false') {
      return false
    }
    return result
  } catch (e) {
    // console.log(e)
    return null
  }
}

export default getCookie
