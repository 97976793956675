import React from "react";
import ReactDOM from 'react-dom'
import EditDataBlock from "./EditDataBlock";
import MyButton from "./MyButton";
import '../styles/MoreDetailedTerminalPopup.css'
import cn from "classnames";

const dialogFieldDataList = [
  { fieldName: 'systemId', label: 'Идентификатор системы' },
  { fieldName: 'ipAddress', label: 'IP-адрес' },
  { fieldName: 'description', label: 'Описание' }
]

const licenseFieldDataList = [
  { fieldName: 'isLicenseActive', label: 'Лицензия активна', showValue: (value) => value ? 'Да' : 'Нет' },
]

const MoreDetailedTerminalPopup = ({ dialogData, onClosePress, onEditPress }) => {
  return ReactDOM.createPortal(
    <section className={'licensePopupBackdrop'}>
      <div className={cn('terminalEditPopup', 'moreDetailedTerminalPopup')}>
        <h1>Информация о планшете</h1>

        <div className={cn('terminalEditPopupDataContainer', 'moreDetailedTerminalPopupDataContainer')}>
          <EditDataBlock
            title={'Общие сведения'}
            data={dialogData}
            fieldDataList={dialogFieldDataList}
            readOnly={true}
          />

          <EditDataBlock
            title={'Лицензия'}
            data={dialogData}
            fieldDataList={licenseFieldDataList}
            readOnly={true}
          />
        </div>

        <div className='terminalEditPopupButtonContainer'>
          <MyButton onClick={onClosePress} mod="myButton_white">Закрыть</MyButton>
          <MyButton onClick={onEditPress} mod='licensePopupSaveButton'>Изменить</MyButton>
        </div>
      </div>
    </section>,
    window.document.body
  )
}

export default MoreDetailedTerminalPopup
