import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import MyButton from './MyButton'
import '../styles/DeletePopup.css'

export default function ({ type, name, onDelete, onCancel }) {
   useEffect(() => {
    const pageNode = document.getElementById('root').childNodes[0];

    pageNode.style.height = "100%";
    pageNode.style.overflowY = "hidden";

    return () => {
      pageNode.style.overflowY = "scroll";
    }
  }, []);

  return ReactDOM.createPortal(
    <section className="deletePopupScreen" >
      <div className="deletePopup_cover" />
      <section className="deletePopup" >
        <div className="deletePopup_labelBlock" >
          <span className="deletePopup_label" >
            Вы уверены, что хотите удалить {type} {name}?
          </span>
          <span className="deletePopup_label" >
            Отменить это действие будет невозможно!
          </span>
        </div>
        <div className="deletePopup_choiceBlock" >
          <MyButton onClick={ onDelete } >Я уверен!</MyButton>
          <MyButton onClick={ onCancel } mod="myButton_white" >Отменить</MyButton>
        </div>
      </section>
    </section>
  , window.document.body)
}