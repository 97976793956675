import React, { useState, useEffect } from 'react'
import { Dots } from 'react-preloaders'

import { getLicenseCodes, generateLicenseCode, getActivations } from '../../api/license'
import MyButton from '../MyButton'
import LicenseCodesList from './LicenseCodesList'
import ActivationsList from './ActivationsList'
import styles from '../../styles/LicenseCodes.css'
import getCookie from '../../utils/getCookie'
import LicensePopup from '../LicensePopup'

export default function LicensePage() {
  const [loading, setLoading] = useState(true)
  const [licenseCodes, setLicenseCodes] = useState({ total: null, data: [] })
  const [activations, setActivations] = useState({ total: null, data: [] })
  const [showLicensePopup, setShowLicensePopup] = useState(false)

  async function createLicenseCode({ inn, clientName, orderId, comment }) {
    // глобальный стейт через куки. Куда мы пришли...

    let savedPageSize = getCookie(`krust__datagrid__LicenseCodesList__pageSize`)
    if (!savedPageSize) {
      savedPageSize = 10
    }
    const effinRslt = await generateLicenseCode({ inn, clientName, orderId, comment })

    setShowLicensePopup(false)

    await drawLicenseCodes({ offset: 0, limit: savedPageSize })
  }
  async function drawLicenseCodes({ offset, limit }) {
    setLoading(true)
    const rslt = await getLicenseCodes({ offset, limit })
    setLicenseCodes(rslt)
    setLoading(false)
  }

  async function drawActivations({ offset, limit }) {
    setLoading(true)
    const rslt = await getActivations({ offset, limit })
    setActivations(rslt)
    setLoading(false)
  }

  const onShowLicensePopupButtonPress = () => {
    setShowLicensePopup(true)
  }

  const onCancelLicenseCreationButtonPress = () => {
    setShowLicensePopup(false)
  }

  return (<section className="pageSection">
    <h1 className="sectionHeader">Лицензионные коды и активации</h1>
    <MyButton onClick={onShowLicensePopupButtonPress}>Сгенерировать лицензионный код</MyButton>
    <h2>Список лицензионных кодов</h2>
    <hr className="licenseCodes__separator"/>
    <LicenseCodesList
      drawLicenseCodes={drawLicenseCodes}
      loading={loading}
      setLoading={setLoading}
      licenseCodes={licenseCodes}
    />
    <h2>Список активаций</h2>
    <hr className="licenseCodes__separator"/>
    <ActivationsList
      drawActivations={drawActivations}
      loading={loading}
      setLoading={setLoading}
      activations={activations}
    />
    <br/>
    <Dots customLoading={ loading } background="blur" color="#5e8adf" time={0} />
    { 
      showLicensePopup && 
      <LicensePopup 
        onCancelPress={onCancelLicenseCreationButtonPress} 
        onSavePress={createLicenseCode}
      />
    }
  </section>)
}
