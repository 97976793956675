export function updateEntityList(sourceList = [], entityIdentifierFieldName, {
  entitiesToAdd = [],
  entitiesToUpdate = [],
  entitiesToRemove = []
}) {
  const resultEntityList = [...entitiesToAdd]

  for (const entity of sourceList) {
    const entityId = entity[entityIdentifierFieldName]

    if (entitiesToRemove.find((entry) => entry[entityIdentifierFieldName] === entityId)) {
      continue;
    }

    const updatedEntity = entitiesToUpdate.find((entry) => entry[entityIdentifierFieldName] === entityId)

    if (updatedEntity) {
      resultEntityList.push(updatedEntity)
    } else {
      resultEntityList.push(entity)
    }
  }

  return resultEntityList
}