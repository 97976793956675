import React from 'react'
import { useTable, usePagination, useBlockLayout } from 'react-table'
import getCookie from '../utils/getCookie'
import setCookie from '../utils/setCookie'
import '../styles/DataGrid.css'

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function Table({
  columns,
  data,
  fetchData,
  loading,
  noDataText,
  total,
  id,
  onPageConfigChange = ({ offset, limit }) => {}
}) {
  let savedPageSize = getCookie(`krust__datagrid__${id}__pageSize`)
  if (!savedPageSize) {
    savedPageSize = 10
    setCookie(`krust__datagrid__${id}__pageSize`, 10)
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: Math.ceil(total / Number(savedPageSize)),
    },
    usePagination,
    useBlockLayout,
  )

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    const currentPageConfig = {
      offset: pageIndex * pageSize,
      limit: pageSize
    }

    fetchData(currentPageConfig)

    console.log(currentPageConfig)

    onPageConfigChange(currentPageConfig)
  }, [pageIndex, pageSize])

  // Render the UI for your table
  return (<div>
      <ul className="genericItemList" {...getTableProps()}>
        {headerGroups.map(headerGroup => (
          <li className="appItem_listHeader" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <span className="section_tableHeader dataGridHeader" {...column.getHeaderProps()}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </span>
            ))}
          </li>
        ))}
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <li className="genericItemList_Item appItem_listItem" {...row.getRowProps()}>
              <section className="listItem_section"  {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <div className="linkCaption" {...cell.getCellProps()}>{cell.render('Cell')}</div>
                })}
              </section>
            </li>
          )
        })}
        {
          !page.length && noDataText && (
            <li><p className="listItem_noDataText">{noDataText}</p></li>
          )
        }
      </ul>
      {/*<div>
        {loading ? (
          // Use our custom loading state to show a loading indicator
          <div>Loading...</div>
        ) : (
          <div>
            Страница {page.length} из ~{controlledPageCount}{' '}
          </div>
        )}
      </div> */}
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Страница{' '}
          <strong>
            {pageIndex + 1} из {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Перейти на страницу:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
            setCookie(`krust__datagrid__${id}__pageSize`, e.target.value)
          }}
        >
          {[10, 15, 20, 25, 30].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Показать {pageSize} элементов
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default Table
