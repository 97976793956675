import superagent from "superagent";

export async function getDialogs({ isLicenseActive, offset, limit }) {
    const { body } = await superagent.get('/api/dialogs')
      .query({ offset, limit, isLicenseActive });

    return body;
}

export async function updateDialog(systemId, { isLicenseActive, description }) {
    const { body } = await superagent.put(`/api/dialogs/${systemId}`)
      .send({ description, isLicenseActive });

    return body
}

export async function deleteDialog(systemId) {
    const resp = await superagent.delete(`/api/dialogs/${systemId}`)

    console.log({ resp })
}