import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import DialogList from "./DialogList";
import { getDialogs, updateDialog } from "../api/dialogs";
import MoreDetailedDialogPopup from "./MoreDetailedDialogPopup";
import DialogEditPopup from "./DialogEditPopup";
import MyButton from "./MyButton";
import '../styles/Dialogs.css'
import cn from "classnames";
import { updateEntityList } from "../utils/entities";

const dialogsToActivatePollingInterval = 15000
const previouslyActivatedDialogsPollingInterval = dialogsToActivatePollingInterval

export default function Dialogs({}) {
  const { path } = useRouteMatch();
  const { pathname } = useHistory().location;

  const [dialogsToActivate, setDialogsToActivate] = useState({total: 0, data: []})
  const [dialogs, setDialogs] = useState({total: 0, data: []})
  const [dialogsToActivateLoadingInProgress, setDialogsToActivateLoadingInProgress] = useState(true)
  const [dialogsLoadingInProgress, setDialogsLoadingInProgress] = useState(true)
  const [dialogInConfigData, setDialogInConfigData] = useState(null)
  const [extendedViewDialogData, setExtendedViewDialogData] = useState(null)

  const [selectedDialogToActivateSystemIds, setSelectedDialogToActivateSystemIds] = useState({})
  const [selectedDialogSystemIds, setSelectedDialogSystemIds] = useState({})

  const [dialogsToActivatePageConfig, setDialogsToActivatePageConfig] = useState(null)
  const [dialogsPageConfig, setDialogsPageConfig] = useState(null)

  useEffect(() => {
    if (!dialogsToActivatePageConfig) {
      return;
    }

    const {limit, offset} = dialogsToActivatePageConfig

    const fetchAndSetDialogsToActivate = () => {
      getDialogs({ isLicenseActive: null, limit, offset })
        .then((dialogsToActivateData) => {
          setDialogsToActivate(dialogsToActivateData)
        })
    }

    fetchAndSetDialogsToActivate()

    const dialogsToActivatePollingIntervalId = setInterval(fetchAndSetDialogsToActivate, dialogsToActivatePollingInterval)

    return () => {
      clearInterval(dialogsToActivatePollingIntervalId)
    }
  }, [dialogsToActivatePageConfig])

  useEffect(() => {
    if (!dialogsPageConfig) {
      return;
    }

    const {limit, offset} = dialogsPageConfig

    const fetchAndSetPreviouslyActivatedDialogs = () => {
      getDialogs({ limit, offset })
        .then((previouslyActivatedDialogs) => {
          setDialogs(previouslyActivatedDialogs)
        })
    }

    fetchAndSetPreviouslyActivatedDialogs()

    const previouslyActivatedDialogsPollingIntervalId = setInterval(fetchAndSetPreviouslyActivatedDialogs, previouslyActivatedDialogsPollingInterval)

    return () => {
      clearInterval(previouslyActivatedDialogsPollingIntervalId)
    }
  }, [dialogsPageConfig])

  useEffect(() => {
    const defaultConfig = {
      limit: 10,
      offset: 0
    }

    setDialogsPageConfig(defaultConfig)
    setDialogsToActivatePageConfig(defaultConfig)
  }, [])

  async function drawDialogsToActivate({offset, limit}) {
    setDialogsToActivateLoadingInProgress(true)

    const dialogsToActivate = await getDialogs({offset, limit, isLicenseActive: null})

    setDialogsToActivate(dialogsToActivate)
    setDialogsToActivateLoadingInProgress(false)
  }

  async function drawDialogs({offset, limit}) {
    setDialogsLoadingInProgress(true)

    const dialogs = await getDialogs({offset, limit})

    setDialogs(dialogs)
    setDialogsLoadingInProgress(false)
  }

  const onShowExtendedDialogClick = ({dialogData}) => {
    setExtendedViewDialogData(dialogData)
  }

  const onCloseExtendedDialogClick = () => {
    setExtendedViewDialogData(null)
  }

  const onDetailedPopupEditClick = () => {
    setDialogInConfigData(extendedViewDialogData)
    setExtendedViewDialogData(null)
  }

  const onCloseDialogConfigClick = () => {
    setDialogInConfigData(null)
  }

  const onEditDialogClick = ({dialogData}) => {
    setDialogInConfigData(dialogData)
  }

  const onDialogToActivateListItemSelectionChanged = ({isChecked, item}) => {
    setSelectedDialogToActivateSystemIds((selectedDialogToActivateSystemIds) => {
      const selectedItemSystemIds = {...selectedDialogToActivateSystemIds}

      if (isChecked) {
        selectedItemSystemIds[item.systemId] = true
      } else {
        delete selectedItemSystemIds[item.systemId]
      }

      return selectedItemSystemIds
    })
  }

  const onDialogListItemSelectionChanged = ({isChecked, item}) => {
    setSelectedDialogSystemIds((selectedDialogSystemIds) => {
      const selectedItemSystemIds = {...selectedDialogSystemIds}

      if (isChecked) {
        selectedItemSystemIds[item.systemId] = true
      } else {
        delete selectedItemSystemIds[item.systemId]
      }

      return selectedItemSystemIds
    })
  }

  const changeDialogListLicenseStatus = async (dialogSystemIdList = [], isLicenseActive) => {
    const affectedDialogs = []

    for (const systemId of dialogSystemIdList) {
      try {
        const updatedDialog = await updateDialog(systemId, {isLicenseActive})
        affectedDialogs.push(updatedDialog)
      } catch (e) {
        console.log(`failed to change license status of ${systemId}`)
      }
    }

    return affectedDialogs
  }

  const onDialogToActivateLicenseStatusToggle = (dialogData) => {
    setDialogs(({data, total}) => {
      const updatedDialogList = updateEntityList(data, 'systemId', {
        entitiesToAdd: [{ ...dialogData, isLicenseActive: !dialogData.isLicenseActive }]
      })

      return {
        data: updatedDialogList,
        total: data.length + 1
      }
    })
  }

  const onActivateAllSelectedDialogsToActivateClick = async () => {
    const affectedDialogs = await changeDialogListLicenseStatus(Object.keys(selectedDialogToActivateSystemIds), true)

    setSelectedDialogToActivateSystemIds({})

    setDialogsToActivate(({data, total}) => {
      const updatedDialogList = updateEntityList(data, 'systemId', {
        entitiesToRemove: affectedDialogs
      })

      return {
        data: updatedDialogList,
        total: data.length - affectedDialogs.length
      }
    })

    setDialogs(({data, total}) => {
      const updatedDialogList = updateEntityList(data, 'systemId', {
        entitiesToAdd: affectedDialogs
      })

      return {
        data: updatedDialogList,
        total: data.length + affectedDialogs.length
      }
    })
  }

  const toggleSelectedPreviouslyActivatedDialogLicenseStatus = async (isLicenseActive) => {
    const affectedDialogs = await changeDialogListLicenseStatus(Object.keys(selectedDialogSystemIds), isLicenseActive)

    setSelectedDialogSystemIds({})

    setDialogs(({data, total}) => {
      const updatedDialogList = updateEntityList(data, 'systemId', {
        entitiesToUpdate: affectedDialogs
      })

      return {
        data: updatedDialogList,
        total
      }
    })
  }

  const onActivateAllSelectedDialogsClick = async () => {
    await toggleSelectedPreviouslyActivatedDialogLicenseStatus(true)
  }

  const onDeactivateAllSelectedDialogsClick = async () => {
    await toggleSelectedPreviouslyActivatedDialogLicenseStatus(false)
  }


  const selectedDialogsToActivateLength = Object.keys(selectedDialogToActivateSystemIds).length
  const selectedDialogsLength = Object.keys(selectedDialogSystemIds).length

  return (
    <section className="pageSection">
      {
        extendedViewDialogData && (
          <MoreDetailedDialogPopup
            dialogData={extendedViewDialogData}
            onClosePress={onCloseExtendedDialogClick}
            onEditPress={onDetailedPopupEditClick}
          />
        )
      }

      {
        dialogInConfigData && (
          <DialogEditPopup
            dialogData={dialogInConfigData}
            onClosePress={onCloseDialogConfigClick}
            drawDialogs={drawDialogs}
          />
        )
      }

      <h1 className="sectionHeader">Список планшетов</h1>

      <div className={'dialogLinksContainer'}>
        <Link
          to={path}
          className={cn('dialogChangeTableBtn', { ['dialogChangeTableBtnActive']: pathname === '/admin/dialogs' })}
        >
          Активные планшеты
        </Link>

        <Link
          to={`${path}/dialog-for-activate`}
          className={cn('dialogChangeTableBtn', { ['dialogChangeTableBtnActive']: pathname === '/admin/dialogs/dialog-for-activate' })}
        >
          Запросы на активацию
          {dialogsToActivate.total > 0 && <span className={'dialogForActivateCount'}>{dialogsToActivate.total}</span>}
        </Link>
      </div>

      <Switch>
        <Route exact path={ `${ path }` } >
          <h2>Активные планшеты</h2>
          <div className='terminalsTableWrapper'>
            <div className="dialogGroupActionContainer">
              <a>Выбрано: {selectedDialogsLength}</a>
              <MyButton
                onClick={onActivateAllSelectedDialogsClick}
                disabled={!selectedDialogsLength}
                mod='toggleActivationButton'
              >
                Активировать все
              </MyButton>

              <MyButton
                onClick={onDeactivateAllSelectedDialogsClick}
                disabled={!selectedDialogsLength}
                mod='toggleActivationButtonLarge'
              >
                Деактивировать все
              </MyButton>
            </div>

            <DialogList
              onPageConfigChange={setDialogsPageConfig}
              onItemSelectionChanged={onDialogListItemSelectionChanged}
              onEditDialogPress={onEditDialogClick}
              selectedItemStrIds={selectedDialogSystemIds}
              onMoreDetailedDialogClick={onShowExtendedDialogClick}
              drawDialogs={drawDialogs}
              loading={dialogsLoadingInProgress}
              setLoading={setDialogsLoadingInProgress}
              dialogs={dialogs}
            />
          </div>
        </Route>

        <Route path={ `${path}/dialog-for-activate` }>
          <h2>Запросы на активацию</h2>
          <div className='terminalsTableWrapper'>
            <div className="dialogGroupActionContainer">
              <a>Выбрано: {selectedDialogsToActivateLength}</a>
              <MyButton
                onClick={onActivateAllSelectedDialogsToActivateClick}
                disabled={!selectedDialogsToActivateLength}
                mod='toggleActivationButton'
              >
                Активировать все
              </MyButton>
            </div>
            <DialogList
              onPageConfigChange={setDialogsToActivatePageConfig}
              drawDialogs={drawDialogsToActivate}
              onLicenseStatusToggle={onDialogToActivateLicenseStatusToggle}
              isEditingBlocked={true}
              onItemSelectionChanged={onDialogToActivateListItemSelectionChanged}
              selectedItemStrIds={selectedDialogToActivateSystemIds}
              loading={dialogsToActivateLoadingInProgress}
              noDataText={'Нет запросов на активацию'}
              setLoading={setDialogsToActivateLoadingInProgress}
              dialogs={dialogsToActivate}
            />
          </div>
        </Route>
      </Switch>

    </section>
  )
}
