import React, { useState, useEffect } from 'react'
import { Dots } from 'react-preloaders'

import { updateLicenseCode } from '../../api/license'
import DataGrid from '../DataGrid'
import MyButton from '../MyButton'

import '../../styles/LicenseCodesList.css'

export default function LicenseCodesList(props) {
  const { loading, setLoading, drawLicenseCodes, licenseCodes } = props
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  async function updateLicenseStatus(codeId, limit, comment, licenseStatus, pageSize, pageIndex) {
    await updateLicenseCode(codeId, limit, comment, licenseStatus)
    drawLicenseCodes({ offset: 0, limit: pageSize })
  }
  async function increaseActivationsLimit(codeId, limit, comment, pageSize, pageIndex) {
    await updateLicenseCode(codeId, limit + 1, comment)
    // Стандартная сортировка поместит новый код вверх. Наверное, лучше кидать на первую страницу.
    drawLicenseCodes({ offset: 0, limit: pageSize })
  }
  async function decreaseActivationsLimit(codeId, limit, comment, pageSize, pageIndex) {
    if (limit > 0) {
      await updateLicenseCode(codeId, limit - 1, comment)
      drawLicenseCodes({ offset: 0, limit: pageSize })
    }
  }
  async function saveComment(codeId, limit, comment, pageSize, pageIndex) {
    await updateLicenseCode(codeId, limit, comment)
    drawLicenseCodes({ offset: 0, limit: pageSize })
  }
  const columns = React.useMemo(
  () => [
    {
      Header: 'Код',
      accessor: 'strId',
      width: 200,
    },
    {
      Header: 'Всего активаций',
      accessor: 'existingActivations',
      width: 100,
    },
    {
      Header: 'Лимит активаций',
      accessor: 'maxActivations',
      width: 100,
      Cell: (params) => {
        return (<div>
          <span className="valueSpan">{params.value}</span>
          <button
            className="shifterButton"
            disabled={params.value <= 0}
            onClick={() => {
              decreaseActivationsLimit(
                params.row.values.strId,
                params.value,
                params.row.values.comment,
                params.state.pageSize,
                params.state.pageIndex,
              )
            }}
          >-</button>
          <button
            className="shifterButton"
            onClick={() => {
              increaseActivationsLimit(
                params.row.values.strId,
                params.value,
                params.row.values.comment,
                params.state.pageSize,
                params.state.pageIndex,
              )
            }}
            >+</button>
        </div>)
      }
    },
    {
      Header: 'Дата создания',
      accessor: 'createdAt',
      Cell: ({ value }) => (value ? new Date(value).toLocaleString('ru-RU', dateOptions) : ''),
      width: 160,
    },
    {
      Header: 'Дата активации',
      accessor: 'activationDate',
      Cell: (params) => {
        if (!params.row.values.activationDate && !params.row.values.deactivationDate) {
          return ''
        }

        return (params.value && params.row.values.isActive) ? new Date(params.value).toLocaleString('ru-RU', dateOptions) : 'код неактивен'
      },
      width: 160,
    },
    {
      Header: 'Дата деактивации',
      accessor: 'deactivationDate',
      Cell: (params) => {
        if (!params.row.values.activationDate && !params.row.values.deactivationDate) {
          return ''
        }

        return (params.value && !params.row.values.isActive) ? new Date(params.value).toLocaleString('ru-RU', dateOptions) : 'код активен'
      },
      width: 160,
    },
    {
      Header: 'Номер заказа',
      accessor: 'orderId',
      width: 160,
    },
    {
      Header: 'Имя клиента',
      accessor: 'clientName',
      width: 160,
    },
    {
      Header: 'ИНН клиента',
      accessor: 'inn',
      width: 160,
    },
    {
      Header: 'Комментарий',
      accessor: 'comment',
      width: 250,
      Cell: (params) => {
        const [comment, setComment] = useState('')
        const [pristine, setPristine] = useState(true)
        useEffect(() => {
          setComment(params.value)
          setPristine(true)
        }, [params.value])
        return (<div className="licenseCodes__row-container">
          <textarea
            value={comment || ''}
            rows={2}
            className="licenseCodes__row-textarea"
            onChange={e => { setComment(e.target.value); setPristine(false) }}
          />
          <button
            className="shifterButton"
            disabled={pristine}
            onClick={async () => {
              await saveComment(
                params.row.values.strId,
                params.row.values.maxActivations,
                comment,
                params.state.pageSize,
                params.state.pageIndex,
              )
              setComment(params.value)
              setPristine(true)
            }}
          >💾</button>
        </div>)
      }
    },
    {
      Header: 'Статус',
      accessor: 'isActive',
      width: 225,
      Cell: (params) => {        
        const title = params.value === true ? 'Деактивировать' : 'Активировать'

        console.log(params.value)
        
        const onToggleActivationClick = () => {
          const newIsActiveValue = !params.value

          updateLicenseStatus(
            params.row.values.strId,
            params.row.values.maxActivations,
            params.row.values.comment,
            newIsActiveValue,
            params.state.pageSize,
            params.state.pageIndex,
          )
        }

        return (
          <div className='toggleActivationButtonContainer'>
            <MyButton onClick={onToggleActivationClick} mod='toggleActivationButton'>{title}</MyButton>
          </div>
        )
      }
    },
  ],
  []
)

  return (
    <DataGrid
      columns={columns}
      data={licenseCodes.data}
      fetchData={drawLicenseCodes}
      loading={loading}
      total={licenseCodes.total}
      id="LicenseCodesList"
    />
  )
}
