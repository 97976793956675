import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import MyButton from './MyButton'
import '../styles/TerminalEditPopup.css'
import EditDataBlock from "./EditDataBlock";
import { deleteDialog, updateDialog } from "../api/dialogs";


const dialogFieldDataList = [
  { fieldName: 'description', label: 'Описание' }
]

export default function DialogEditPopup({ dialogData: dialogDataProp, onSavePress, onClosePress, drawDialogs, dataGridState }) {
    const [dialogData, setDialogData] = useState(dialogDataProp)

    const onToggleActivationClick = () => {
        setDialogData({ ...dialogData, isLicenseActive: !dialogData.isLicenseActive })
    }

    const onSaveButtonPress = async () => {
        const { systemId, isLicenseActive, description } = dialogData

        await updateDialog(systemId, { isLicenseActive, description })
        drawDialogs({ offset: 0, limit: dataGridState?.pageSize })

        onClosePress()
    }

    const onRemoveDialogClick = async () => {
        const { systemId, description } = dialogData

        const removalConfirmed = window.confirm(`Вы уверены, что хотите удалить планшет ${systemId} (описание: ${description})?`)

        if (removalConfirmed) {
            await deleteDialog(dialogData.systemId)

            drawDialogs({ offset: 0, limit: dataGridState?.pageSize })
            onClosePress()
        }
    }

    return ReactDOM.createPortal(
        <section className='licensePopupBackdrop' >
            <div className='terminalEditPopup'>
                <h1>Редактировать информацию о планшете</h1>

                <div className='terminalEditPopupDataContainer'>
                    <EditDataBlock
                        title={'Общие сведения'}
                        data={dialogData}
                        setData={setDialogData}
                        fieldDataList={dialogFieldDataList}
                    />
                </div>



                <div className='terminalEditPopupButtonContainer'>
                    <MyButton onClick={onClosePress} mod="myButton_white" >Закрыть</MyButton>
                    <MyButton onClick={onSaveButtonPress} mod='licensePopupSaveButton'>Сохранить</MyButton>
                    <MyButton onClick={onToggleActivationClick} mod='licensePopupSaveButton'>
                        {dialogData.isLicenseActive ? 'Деактивировать' : 'Активировать'}
                    </MyButton>
                    <MyButton onClick={onRemoveDialogClick} mod={cn('myButton_red', 'myButton_ml-15')}>Удалить</MyButton>
                </div>

                </div>
        </section>
      , window.document.body)
}
