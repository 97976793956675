import React, { useState } from 'react'

import MyTextInput from "./MyTextInput";
import MyButton from "./MyButton";
import { createLicense } from '../api/license'

export default function CreateLicense({ mainUser }) {

  const [token, setToken] = useState('')
  const [license, setLicense] = useState('')
  const [error, setError] = useState('')

  async function onClickListener() {
    if (!token) {
      setError('введите токен устройства')
      return
    }
    const licenseText = await createLicense(token)
    setLicense(licenseText)
  }

  function tokenInputHandler({ target }) {
    setToken(target.value)
    if (error) setError('')
  }

  return (
    <section className="pageSection">
      <h1 className="sectionHeader">Создание лицензии</h1>
      <div>
        <label htmlFor="name" >Токен</label>
        <br />
        <MyTextInput
          mod="token-field"
          hasError={ error }
          id="androidToken"
          name="androidToken"
          placeholder="Введите идентификатор устройства..."
          onChange={tokenInputHandler}
          value={token}
        />
        <br/>
        {error && error}
      </div>
      <div>
        <MyButton onClick={onClickListener}>
          Создать лицензию
        </MyButton>
      </div>
      <div>
        <label htmlFor="license" >Лицензия</label>
        <br />
        <textarea
          className="textarea-license"
          id="license"
          value={license}
          readOnly
        />
      </div>
    </section>

  )
}