import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { ListboxInput, ListboxButton, ListboxPopover, ListboxList, ListboxOption } from '@reach/listbox'

import { useUser, logout } from '../api/auth'
import { isAdmin, isKioskOperator, isManager } from '../utils/permissions'

import '../styles/MainMenu.css'
import '@reach/listbox/styles.css'

function getCurrentPage(history) {
  if (history.startsWith('/admin/applications') || history === '/admin') return 'Приложения';
  else if (history.startsWith('/admin/users')) return 'Пользователи';
  if (history.startsWith('/admin/create-license')) {
    return 'Лицензия'
  }
  if (history.startsWith('/admin/license-codes')) {
    return 'Активации'
  }
  if (history.startsWith('/admin/terminals')) {
    return 'Терминалы'
  }
  if (history.startsWith('/admin/dialogs')) {
    return 'Планшеты'
  }

  return 'Статистика'
}

export default function AdminMainMenu() {
  const historyHook = useHistory()
  const history = useHistory().location.pathname;
  const [ currentPage, setCurrentPage ] = useState(getCurrentPage(history));
  
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 420px)'
  });

  const user = useUser();

  useEffect(() => {
    console.log(user)

    if (isKioskOperator(user)) {
      historyHook.push('/admin/terminals')
    }
  }, [user])

  if (!user) {
    return <nav />
  }
 

  const isUserAdmin = isAdmin(user);
  const isUserManager = isManager(user);
  const isUserKioskOperator = isKioskOperator(user)

  console.log(currentPage)


  return (isMobileDevice ? (
    <nav className="mainNavMenu" >
      <ListboxInput onChange={ setCurrentPage } value={ currentPage } className="mainNavList" >
        <ListboxButton arrow={ <span className="icon_arrowDown" /> } />
        <ListboxPopover portal={ false } className="mainNavList_popover" >
          <ListboxList>
            <ListboxOption value="default" disabled={ true } className="mainNavListItem" >
              { currentPage }
              <span className="icon_arrowDown listboxOption_arrowIcon" />
            </ListboxOption>
            { (isUserAdmin || isUserManager) &&
              <ListboxOption value="Приложения" className={`mainNavListItem ${currentPage === "Приложения" && "hidden"}`} >
                <Link to="/admin" >Приложения</Link>
              </ListboxOption>
            }
            { isUserAdmin &&
              <ListboxOption value="Пользователи" className={`mainNavListItem ${currentPage === "Пользователи" && "hidden"}`} >
                <Link to="/admin/users" >Пользователи</Link>
              </ListboxOption>
            }
            { (isUserAdmin || isUserManager) &&
              <ListboxOption value="Статистика" className={`mainNavListItem ${currentPage === "Статистика" && "hidden"}`} >
                <Link to="/admin/statistics" >Статистика</Link>
              </ListboxOption>
            }
            { (isUserAdmin || isUserManager) &&
            <ListboxOption value="Лицензия" className={`mainNavListItem ${currentPage === "Лицензия" && "hidden"}`} >
              <Link to="/admin/create-license" >Лицензия</Link>
            </ListboxOption>
            }
            { (isUserAdmin || isUserManager) &&
            <ListboxOption value="Активации" className={`mainNavListItem ${currentPage === "Активацит" && "hidden"}`} >
              <Link to="/admin/license-codes" >Активации</Link>
            </ListboxOption>
            }
            { (isUserAdmin || isUserManager || isUserKioskOperator) &&
            <ListboxOption value="Терминалы" className={`mainNavListItem ${currentPage === "Терминалы" && "hidden"}`} >
              <Link to="/admin/terminals" >Терминалы</Link>
            </ListboxOption>
            }
            { (isUserAdmin || isUserManager || isUserKioskOperator) &&
            <ListboxOption value="Планшеты" className={`mainNavListItem ${currentPage === "Планшеты" && "hidden"}`} >
              <Link to="/admin/terminals" >Планшеты</Link>
            </ListboxOption>
            }
            <ListboxOption  disabled={ true } value="Имя пользователя" className="mainNavListItem" >{ user.firstName } { user.lastName }</ListboxOption>
          </ListboxList>
        </ListboxPopover>
      </ListboxInput>
      <ul className="mainNavList mainNavList__right mainNavList__rightResponsive" >
        { user.avatar &&
        <li className="mainNavListItem__avatar" >
          <img src={ user.avatar } alt="user_icon" />
        </li> }
        <li className="mainNavListItem__right" ><button className="mainButton__red" onClick={ logout } >Выход</button></li>
      </ul>
    </nav>
    ) : (
    <nav className="mainNavMenu" >
      <ul className="mainNavList" >
        { (isUserAdmin || isUserManager) && <li className="mainNavListItem" >
          <Link className={ `mainLink ${getCurrentPage(history) === "Приложения" && "mainLink_active"}` } to="/admin" >Приложения</Link>
        </li> }
        { isUserAdmin && <li className="mainNavListItem" >
          <Link className={ `mainLink ${getCurrentPage(history) === "Пользователи" && "mainLink_active"}` } to="/admin/users" >Пользователи</Link>
        </li> }
        { (isUserAdmin || isUserManager) && <li className="mainNavListItem" >
          <Link className={ `mainLink ${getCurrentPage(history) === "Статистика" && "mainLink_active"}` } to="/admin/statistics" >Статистика</Link>
        </li> }
        { (isUserAdmin || isUserManager) && <li className="mainNavListItem" >
          <Link className={ `mainLink ${getCurrentPage(history) === "Лицензия" && "mainLink_active"}` } to="/admin/create-license" >Лицензия</Link>
        </li> }
        { (isUserAdmin || isUserManager) && <li className="mainNavListItem" >
          <Link className={ `mainLink ${getCurrentPage(history) === "Активации" && "mainLink_active"}` } to="/admin/license-codes" >Активации</Link>
        </li> }
        { (isUserAdmin || isUserManager || isUserKioskOperator) && <li className="mainNavListItem" >
          <Link className={ `mainLink ${getCurrentPage(history) === "Терминалы" && "mainLink_active"}` } to="/admin/terminals" >Терминалы</Link>
        </li> }
        { (isUserAdmin || isUserManager || isUserKioskOperator) && <li className="mainNavListItem" >
          <Link className={ `mainLink ${getCurrentPage(history) === "Планшеты" && "mainLink_active"}` } to="/admin/dialogs" >Планшеты</Link>
        </li> }
        { (false && (isUserAdmin || isUserManager)) && <li className="mainNavListItem" >
          <a target={"_blank"} className={ `mainLink` } href="http://master.solo.krust.tech/admin">
            ТФП Сонэд
          </a>
        </li> }
        { (isUserAdmin || isUserManager) && <li className="mainNavListItem" >
          <a target={"_blank"} className={ `mainLink` } href="http://development.solo.krust.tech/admin">
            ТФП Войса
          </a>
        </li> }
      </ul>
      <ul className="mainNavList mainNavList__right mainNavList__rightResponsive" >
        { user.avatar &&
        <li className="mainNavListItem__avatar" >
          <img src={ user.avatar } alt="user_icon" />
        </li> }
        <li className="mainNavListItem__right" >{ user.firstName } { user.lastName }</li>
        <li className="mainNavListItem__right" ><button className="mainButton__red" onClick={ logout } >Выход</button></li>
      </ul>
    </nav>
  )
  )
}
