import React, { useState, useEffect } from 'react'

import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { Dots } from 'react-preloaders'
import moment from 'moment'
import 'moment/locale/ru'

import appApi from '../api/applications'
import DeletePopup from './DeletePopup'

import '../styles/Application.css'

moment.locale('ru');

function AppItem({ app, onDelete }) {
  const [showPopup, setShowPopup] = useState(false);

  const isTabletDevice = useMediaQuery({
    query: '(max-device-width: 700px)'
  });

  const onClickDelete = () => {
    appApi.deleteApplication(app.strId)
      .then(status => status === 200 && onDelete(app.strId))
  };

  const showDeletePopup = () => {
    setShowPopup(true)
  };
  const hideDeletePopup = () => {
    setShowPopup(false)
  };

  return (
    <li className="genericItemList_Item appItem_listItem" >
      <section className="listItem_section" >
        <div className="linkImage" >
          { app.avatar ? <img src={ app.avatar } alt="app_icon" /> : <div /> }
        </div>
        <span className="linkCaption appItem_nameValue" >{ app.name }</span>
        { !isTabletDevice &&
          <span className="linkCaption appItem_date" >{ moment(app.updatedAt).format('D MMMM, YYYY') }</span>
        }
        <span className="rightPanel" >
          <Link
            to={ `/admin/applications/${ app.strId }` }
            className="rightPanel_config"
          >
            <div className="icon_config" />
          </Link>
          <a onClick={ showDeletePopup } className="rightPanel_delete" >
            <div className="icon_delete" />
          </a>
        </span>
      </section>
      {showPopup &&
        <DeletePopup
          type="приложение"
          name={ app.name }
          onDelete={ onClickDelete }
          onCancel={ hideDeletePopup }
        /> }
    </li>
  )
}

export default function Applications({ mainUser }) {
  const [ loading, setLoading ] = useState(true);
  const [applications, setApplications] = useState([]);
  const [deletedApp, setDeletedApp] = useState(null);
  const isTabletDevice = useMediaQuery({
    query: '(max-device-width: 700px)'
  });
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 420px)'
  });

  useEffect(() => {
    appApi.getApplicationList()
      .then(setApplications)
      .then(() => setLoading(false))
      .catch((e) => {
        setLoading(false);
        console.error('ERROR get application list', e.message)
      })
  }, [setApplications, deletedApp, mainUser]);

  return (
    <section className="pageSection" >
      { !isMobileDevice && <h1 className="sectionHeader appPageHeader" >Приложения</h1> }
      <ul className="genericItemList" >
        { isMobileDevice ? (
          <li className="appItem_listHeader" />
          ) : (
          <li className="appItem_listHeader" >
            <span className="section_tableHeader" >Иконка</span>
            <span className="section_tableHeader appItem_nameHeader" >Название приложения</span>
            { !isTabletDevice &&
              <span className="section_tableHeader appItem_date" >Последнее обновление</span>
            }
          </li>
        )}
        {
          applications && applications.length > 0 &&
          applications.map((application) => <AppItem key={ application.id } app={ application } onDelete={ setDeletedApp } /> )
        }
        <li className="appItem_addButton" >
          <Link className="genericItemList_LinkPrimary" to="/admin/applications/add" >
            <div className="genericItemList_LinkPrimary_block" >
              <span className="icon_addApp" />
              <span className="linkCaption blue" >Добавить приложение</span>
            </div>
          </Link>
        </li>
      </ul>
      <Dots customLoading={ loading } background="blur" color="#5e8adf" time={0} />
    </section>
  )
}
