import React, { useState, useEffect } from 'react'
import { Dots } from 'react-preloaders'

import { removeTerminal, updateLicenseCode, updateTerminal } from '../api/license'
import DataGrid from './DataGrid'
import MyButton from './MyButton'

import '../styles/LicenseCodesList.css'
import { deleteDialog, updateDialog } from "../api/dialogs";
import cn from "classnames";

export default function DialogList({ 
  loading, 
  setLoading,
  drawDialogs,
  selectedItemStrIds = {},
  onItemSelectionChanged = () => {},
  onLicenseStatusToggle = () => {},
  onPageConfigChange,
  dialogs,
  onEditDialogPress,
  isEditingBlocked = false,
  onMoreDetailedDialogClick = () => {},
  noDataText
}) {
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  async function updateLicenseStatus(systemId, isLicenseActive, pageSize) {
    await updateDialog(systemId, { isLicenseActive })
    drawDialogs({ offset: 0, limit: pageSize })
  }

  const columns = React.useMemo(
  () => [
    {
      width: 50,
      Header: ' ',
      Cell: (params) => {
        const itemSystemId = params.row.original.systemId

        const onCheckboxValueChange = (e) => {
          onItemSelectionChanged({ isChecked: e.target.checked, item: params.row.original })
        }

        return (
          <div key={itemSystemId} className="dialogTableFirstCellValue linkCaption">
            <input id={`checkbox-${itemSystemId}`} type='checkbox' onChange={onCheckboxValueChange} checked={selectedItemStrIds[itemSystemId]}/>
          </div>
        )
      }
    },
    {
      Header: 'Статус',
      Cell: (params) => {
        const isDeviceOffline = new Date().getTime() - new Date(params.row.original.lastPingAt).getTime() > 15000

        return <div className="dialogTableFirstCellValue linkCaption">{ isDeviceOffline ? 'Не в сети' : 'В сети'}</div>
      }
    },
    {
      Header: 'Идентификатор устройства',
      accessor: 'systemId',
      width: 250,
    },
    {
      Header: 'Описание',
      accessor: 'description',
      width: 400,
    },
    {
      Header: 'Дата активации',
      accessor: 'createdAt',
      Cell: ({ value }) => (value ? new Date(value).toLocaleString('ru-RU', dateOptions) : ''),
      width: 160,
    },
    {
      Header: 'Последнее соединение',
      accessor: 'lastPingAt',
      Cell: ({ value }) => (value ? new Date(value).toLocaleString('ru-RU', dateOptions) : ''),
      width: 200,
    },
    {
      Header: 'Статус лицензии',
      accessor: 'isLicenseActive',
      width: 225,
      Cell: (params) => {
        const title = params.value === true ? 'Деактивировать' : 'Активировать'

        const onToggleActivationClick = () => {
          updateLicenseStatus(params.row.original.systemId, !params.value)

          onLicenseStatusToggle(params.row.original)
        }

        return (
          <div className='toggleActivationButtonContainer'>
            <MyButton onClick={onToggleActivationClick} mod='toggleActivationButton'>{title}</MyButton>
          </div>
        )
      }
    },
    {
      width: 225,
      id: 'moreDetailedTerminalButton',
      Cell: (params) => {
        const onButtonPress = () => {
          onMoreDetailedDialogClick({ dialogData: params.row.original, tableState: params.state })
        }

        return (
          <div className='toggleActivationButtonContainer'>
            <MyButton onClick={onButtonPress} mod='toggleActivationButton' disabled={isEditingBlocked}>Подробнее</MyButton>
          </div>
        )
      }
    },
    {
      width: 40,
      id: 'editTerminalButton',
      Cell: (params) => {
        const onButtonPress = () => {
          if (!isEditingBlocked) {
            onEditDialogPress({dialogData: params.row.original, tableState: params.state})
          }
        }

        return (
          <a onClick={onButtonPress} className={isEditingBlocked ? 'editTerminalButtonDisabled' : 'editTerminalButton'} title='Изменить'> ✏️</a>
        )
      }
    },
    {
      width: 40,
      id: 'removeTerminalButton',
      Cell: (params) => {
        const onRemoveDialogClick = async () => {
          const { systemId, description } = params.row.original

          const removalConfirmed = window.confirm(`Вы уверены, что хотите удалить планшет ${systemId} (описание: ${description})?`)

          if (removalConfirmed) {
            await deleteDialog(params.row.original.systemId)
            drawDialogs({ offset: 0, limit: params.state.pageSize })
          }
        }

        return (
          <a onClick={onRemoveDialogClick} className='removeTerminalButton' title='Удалить'>🗑️</a>
        )
      }
    },
  ],
  [selectedItemStrIds]
)
  return (
    <DataGrid
      columns={columns}
      data={dialogs.data}
      noDataText={noDataText}
      fetchData={drawDialogs}
      onPageConfigChange={onPageConfigChange}
      loading={loading}
      total={dialogs.total}
      id="LicenseCodesList"
    />
  )
}
