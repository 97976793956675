import React from 'react'
import { Redirect } from 'react-router-dom'

import { isAdmin, isManager, isKioskMaster, isKioskOperator } from '../utils/permissions'
import { useUser, logout } from '../api/auth'

import MyButton from './MyButton'

export default function () {
  const user = useUser();

  if (!user) {
    return <Redirect to={{ pathname: "/login" }} />
  }

  if (isAdmin(user) || isManager(user) || isKioskOperator(user)) {
    return <Redirect to={{ pathname: "/admin" }} />
  }

  return (
    <div className="pageSection noRolePage" >
      <p className="noRolePage_title" >Здравствуйте, { user.firstName } { user.lastName }.</p>
      { !isKioskMaster(user) && !isKioskOperator(user) && <p>
        К сожалению, системный администратор не назначил вам роли в системе.
      </p> }
      <p>
        Для продолжения работы, пожалуйста, обратитесь к вашему системному администратору.
      </p>
      <p ><MyButton onClick={ logout } >Выйти из системы</MyButton></p>
    </div>
  )
}
