import React, { useState, useEffect, useRef } from 'react'
import Chart from 'chart.js'
import getStatistics from '../api/statistics'

import { ListboxInput, ListboxButton, ListboxPopover, ListboxList, ListboxOption } from '@reach/listbox'
import '@reach/listbox/styles.css'

const MONTHS = {
  '00': 'Весь год',
  '01': 'Январь',
  '02': 'Февраль',
  '03': 'Март',
  '04': 'Апрель',
  '05': 'Май',
  '06': 'Июнь',
  '07': 'Июль',
  '08': 'Август',
  '09': 'Сентябрь',
  '10': 'Октябрь',
  '11': 'Ноябрь',
  '12': 'Деккабрь'
};

export default function ({ appId }) {
  const formRef = useRef(null);
  const canvasRef = useRef(null);
  const initYear = 2019;
  const todayYear = +(new Date().getFullYear());
  const [ years, setYears ] = useState([]);
  const [ data, setData ] = useState(null);
  const [ chart, setChart ] = useState(null);
  const [ yearSelect, setYearSelect ] = useState(todayYear.toString());
  const [ monthSelect, setMonthSelect ] = useState('00');
  const ctx = canvasRef.current;

  useEffect(() => {
    setYears(Array.from(Array.from(Array(Math.ceil(todayYear - initYear + 1)).keys()), x => initYear + x));
  }, [todayYear]);

  useEffect(() => {
    const draw = () => {
      if (chart) {
        chart.data.labels = Object.keys(data.setup);
        chart.data.datasets[0].data = Object.values(data.setup);
        chart.data.datasets[1].data = Object.values(data.update);
        chart.update();
      } else
        setChart(new Chart(ctx, {
          type: 'line',
          data: {
            labels: Object.keys(data.setup),
            datasets: [{
              label: 'Количество обновлений',
              lineTension: 0.2,
              fill: false,
              backgroundColor: '#5E8ADF',
              borderColor: '#5E8ADF',
              data: Object.values(data.update),
            }, {
              label: 'Количество установок',
              lineTension: 0.2,
              backgroundColor: '#E13151',
              borderColor: '#E13151',
              data: Object.values(data.setup),
              fill: false,
            }]
          },
          options: {
            legend: {
              position: 'bottom',
              align: 'start',
              labels: {
                fontColor: '#303741',
                fontFamily: 'Manrope, sans-serif',
                padding: 20
              }
            },
            responsive: false,
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              xAxes: [{
                display: true,
              }],
              yAxes: [{
                display: true,
                ticks: {
                  stepSize: 1
                }
              }]
            }
          }
        }));
    };

    if (data) draw()
  }, [data, chart, ctx]);

  useEffect(() => {
    getStatistics(appId, yearSelect, monthSelect)
      .then(setData)
  }, [appId, yearSelect, monthSelect]);

  return (
    <section >
      <form ref={ formRef } className="statPage_form">
        <ListboxInput onChange={ setYearSelect } value={ yearSelect } >
          <ListboxButton arrow={ <span className="icon_arrowDown" /> } />
          <ListboxPopover portal={ false }>
            <ListboxList>
              <ListboxOption value="default" disabled={ true } >
                { yearSelect }
                <span className="icon_arrowDown listboxOption_arrowIcon" />
              </ListboxOption>
              { years.map(year => <ListboxOption key={ year } value={ year.toString() } >{ year }</ListboxOption>) }
            </ListboxList>
          </ListboxPopover>
        </ListboxInput>
        <ListboxInput onChange={ setMonthSelect } value={ monthSelect } >
          <ListboxButton arrow={ <span className="icon_arrowDown" /> } />
          <ListboxPopover portal={ false }>
            <ListboxList>
              <ListboxOption value="default" disabled={ true } >
                { MONTHS[monthSelect] }
                <span className="icon_arrowDown listboxOption_arrowIcon" />
              </ListboxOption>
              <ListboxOption value="00" >Весь год</ListboxOption>
              <ListboxOption value="01" >Январь</ListboxOption>
              <ListboxOption value="02" >Февраль</ListboxOption>
              <ListboxOption value="03" >Март</ListboxOption>
              <ListboxOption value="04" >Апрель</ListboxOption>
              <ListboxOption value="05" >Май</ListboxOption>
              <ListboxOption value="06" >Июнь</ListboxOption>
              <ListboxOption value="07" >Июль</ListboxOption>
              <ListboxOption value="08" >Август</ListboxOption>
              <ListboxOption value="09" >Сентябрь</ListboxOption>
              <ListboxOption value="10" >Октябрь</ListboxOption>
              <ListboxOption value="11" >Ноябрь</ListboxOption>
              <ListboxOption value="12" >Декабрь</ListboxOption>
            </ListboxList>
          </ListboxPopover>
        </ListboxInput>
      </form>
      <section className="statPage_chart">
        <canvas ref={ canvasRef } id="myChart" width="800" height="300" />
      </section>
    </section>
  )
}