import superagent from 'superagent'

export async function createLicense(token) {
  const { body } = await superagent.get(`/api/license-key/${token}`);
  return body;
}

export async function getLicenseCodes({ offset, limit }) {
  const { body } = await superagent.get('/api/license-codes')
    .query({ offset, limit });
  return body;
}

export async function generateLicenseCode({ inn, clientName, orderId, comment }) {
  const { body } = await superagent.post('/api/license-codes').send({ inn, clientName, orderId, comment })
  return body
}

export async function getActivations({ offset, limit }) {
  const { body } = await superagent.get('/api/license-activations')
    .query({ offset, limit })

  return body
}

export async function updateLicenseCode(codeId, maxActivations, comment, isActive) {
  const { body } = await superagent.put(`/api/license-codes/${codeId}`)
    .query({ maxActivations })
    .set('Content-Type', 'application/json')
    .send({ comment, isActive })  

  return body
}

export async function updateLicenseCodeData(licenseCodeData) {
  const { body } = await superagent.put(`/api/license-codes/${licenseCodeData.strId}`)
    .query({ maxActivations: licenseCodeData.maxActivations })
    .set('Content-Type', 'application/json')
    .send(licenseCodeData) 

  return body
}

export async function updateTerminal({ strId, plainAddress, }) {
  const { body } = await superagent.put(`/api/terminals/${strId}`)
    .send({ plainAddress })

  return body
}

export async function removeTerminal({ strId }) {
  const { body } = await superagent.delete(`/api/terminals/${strId}`)

  return body;
}