import React from 'react'
import classNames from 'classnames'
import wrapModifications from '../utils/wrapModifications'
import '../styles/MyTextInput.css'

export default function (props) {
  const { hasError, mod, inputRef = null, ...tail } = props
  const totalProps = {
    className: classNames('myTextInput', {
      inputError: hasError,
      ...wrapModifications(mod)
    }),
    type: 'text',
    ...tail
  }
  return <input ref={ inputRef } { ...totalProps } />
}
