import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { fetchMe, UserContext } from '../api/auth'

import Login from './Login'
import Admin from './Admin'
import NoRolesStub from './NoRolesStub'

import '../styles/App.css'
import '../styles/manrope.css'
import '../styles/MobileResponsive.css'

function App() {
  const [isInited, setInitState] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    fetchMe()
    .then(setUser)
    .then(() => setInitState(true))
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser }} >
      { isInited && <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/" >
          <NoRolesStub />
        </Route>
      </Switch>
    </Router> }    
    </UserContext.Provider>
  )
}

export default App;
