import React, { useState, useEffect } from 'react'
import { Dots } from 'react-preloaders'

import { getLicenseCodes } from '../../api/license'
import DataGrid from '../DataGrid'

export default function LicenseCodesList(props) {
  const { loading, setLoading, drawActivations, activations } = props
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const columns = React.useMemo(
  () => [
    {
      Header: 'Код',
      accessor: 'licenseCodeId',
      width: 200,
    },
    {
      Header: 'Дата активации',
      accessor: 'activationDate',
      Cell: ({ value }) => (value ? new Date(value).toLocaleString('ru-RU', dateOptions) : ''),
      width: 160,
    },
    {
      Header: 'ID системы',
      accessor: 'systemId',
      width: 480,
    },
    {
      Header: 'Приложение',
      accessor: 'application',
      width: 100,
    },
    {
      Header: 'IP адрес',
      accessor: 'ipAddress',
      width: 180,
    }
  ],
  []
)

  return (
    <DataGrid
      columns={columns}
      data={activations.data}
      fetchData={drawActivations}
      loading={loading}
      total={activations.total}
      id="LicenseActivationsList"
    />
  )
}
