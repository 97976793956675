import React, { useEffect, useState } from "react";
import MyTextInput from "./MyTextInput";
import { debounce } from "lodash";
import { getAddressSuggestions } from "../api/dadata";

function EditDataBlock({ title, setData, data, fieldDataList = [], readOnly = false }) {
  const getOnInputValueChangeFunc = (fieldName) => {
    return (e) => {
      const value = e.target.value

      setData((data) => {
        return { ...data, [fieldName]: value}
      })
    }
  }

  const getInputComponentName = (fieldName) => {
    if (fieldName === 'plainAddress') {
      return DadataAddressInput
    }
    else {
      return MyTextInput
    }
  }

  return (
    <div className='terminalEditPopupEditDataBlock'>
      <h3>{title}</h3>
      <div className='terminalEditPopupInputContainer'>
        {
          fieldDataList.map(({ label, fieldName, showValue}) => {
            const value = data[fieldName] || ''
            const InputComponent = getInputComponentName(fieldName)

            return (
              <React.Fragment key={fieldName}>
                <label>{label}</label>
                <InputComponent value={showValue && readOnly ? showValue(value) : value} onChange={getOnInputValueChangeFunc(fieldName)} readOnly={readOnly} />
              </React.Fragment>
            )
          })
        }
      </div>
    </div>
  )
}

function DadataAddressInput({ value, onChange, readOnly = false }) {
  const [suggestions, setSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)

  useEffect(() => {
    document.getElementById('dadataAddressInputField').addEventListener('focus', onInputFocus)
    document.getElementById('dadataAddressInputField').addEventListener('blur', onInputBlur)

    return () => {
      document.getElementById('dadataAddressInputField').removeEventListener('focus', onInputFocus)
      document.getElementById('dadataAddressInputField').removeEventListener('blur', onInputBlur)
    }
  }, [])

  const onInputFocus = () => {
    setShowSuggestions(true)
  }

  const onInputBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false)
    }, 300)
  }

  const updateSuggestions = debounce(() => {
    getAddressSuggestions(value).then((res) => {
      setSuggestions(res.suggestions.map((suggestion) => suggestion.value))
    })
  }, 1000)

  const onChangeInputValue = (e) => {
    updateSuggestions()
    onChange(e)
  }


  return (
    <div className='dadataAddressInputContainer'>
      <MyTextInput id={'dadataAddressInputField'} value={value} onChange={onChangeInputValue} readOnly={readOnly} />
      {
        (showSuggestions && suggestions.length > 0) && (
          <div className='dadataAddressInputSuggestions'>
            {
              suggestions.map((suggestion) => {
                const onClick = (e) => {
                  console.log('onclick')

                  onChange({ target: { value: suggestion } })
                }

                return (
                  <a onClick={onClick}>{suggestion}</a>
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}

export default EditDataBlock
