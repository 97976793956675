import superagent from 'superagent'
import React, { useContext } from 'react'

export const UserContext = React.createContext({ user: null, setUser: null })

export function useUser() {
  const { user } = useContext(UserContext)
  return user
}

export async function authorize(username, password) {
  try {
    const resp = await superagent
      .post('/api/user/authorize')
      .type('form')
      .send({ username, password })
    return resp.body
  } catch (e) {
    return { error: e.status || 500 }
  }
}

export async function fetchMe() {
  try {
    const resp = await superagent.get('/api/user/me')
    const _user = resp.body
    return _user
  } catch (e) {
    return null
  }
}

export async function logout() {
  try {
    await superagent.post('/api/user/logout')
    window.location.reload()
  } catch (e) {
    return null
  }
}
