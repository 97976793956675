import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import '../styles/LicensePopup.css'
import MyButton from './MyButton'
import MyTextInput from './MyTextInput'

export default function LicensePopup({ onSavePress, onCancelPress }) {
    const [orderId, setOrderId] = useState(null)
    const [clientName, setClientName] = useState(null)
    const [inn, setInn] = useState(null)
    const [comment, setComment] = useState(null)

    const onSaveButtonPress = () => {
        onSavePress({
            orderId,
            clientName,
            inn,
            comment
        })
    }    

    return ReactDOM.createPortal(
        <section className='licensePopupBackdrop' >
            <div className='licensePopup'>
                    <h1>Создание лицензии</h1>
                    <div className='licensePopupInputContainer'>
                        <label >Номер заказа</label>
                        <MyTextInput onChange={(e) => setOrderId(e.target.value)} />

                        <label>Имя клиента</label>
                        <MyTextInput onChange={(e) => setClientName(e.target.value)}/>

                        <label>ИНН клиента</label>
                        <MyTextInput onChange={(e) => setInn(e.target.value)}/>

                        <label>Комментарий</label>
                        <MyTextInput onChange={(e) => setComment(e.target.value)}/>
                    </div>  

                    <div>
                        <MyButton onClick={onCancelPress} mod="myButton_white" >Отменить</MyButton>
                        <MyButton onClick={onSaveButtonPress} mod='licensePopupSaveButton'>Создать</MyButton>
                    </div>
                    
                </div>           
        </section>
      , window.document.body)
}