import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import MyButton from './MyButton'
import '../styles/TerminalEditPopup.css'
import EditDataBlock from "./EditDataBlock";
import HistoryDataBlock from "./HistoryDataBlock";
import { removeTerminal, updateLicenseCode } from "../api/license";


export default function TerminalEditPopup({ terminalData, onSavePress, onClosePress, drawTerminals, dataGridState }) {
    const [licenseData, setLicenseData] = useState(terminalData.LicenseCode)
    const [terminalCommonData, setTerminalCommonData] = useState({
        strId: terminalData.strId,
        plainAddress: terminalData.plainAddress
    })

    const onSaveButtonPress = () => {
        onSavePress({
            licenseData,
            terminalCommonData
        })
    }

    const licenseFieldDataList = [
        { fieldName: 'orderId', label: 'Номер заказа' },
        { fieldName: 'clientName', label: 'Имя клиента' },
        { fieldName: 'inn', label: 'ИНН' },
        { fieldName: 'comment', label: 'Комментарий' }
    ]

    const terminalCommonFieldDataList = [
        { fieldName: 'plainAddress', label: 'Местоположение' }
    ]

    async function updateLicenseStatus(codeId, limit, comment, licenseStatus, pageSize, pageIndex) {
        await updateLicenseCode(codeId, limit, comment, licenseStatus)
        drawTerminals({ offset: 0, limit: pageSize })
    }

    const onRemoveTerminalClick = async () => {
        const licenseCodeData = terminalData['LicenseCode']

        const licenseCodeDataStr = ` (ИНН - ${licenseCodeData.inn}, код активации - ${licenseCodeData.strId})`

        const removalConfirmed = window.confirm(`Вы уверены, что хотите удалить терминал клиента ${licenseCodeData.clientName}?${licenseCodeDataStr}`)

        if (removalConfirmed) {
            await removeTerminal({ strId: terminalData.strId })
            drawTerminals({ offset: 0, limit: dataGridState?.pageSize })
            onClosePress()
        }
    }

    const onToggleActivationClick = () => {
        const newIsActiveValue = !terminalData.LicenseCode.isActive

        console.log(terminalData)

        updateLicenseStatus(
          terminalData.LicenseCode.strId,
          terminalData.LicenseCode.maxActivations,
          terminalData.LicenseCode.comment,
          newIsActiveValue,
          dataGridState?.pageSize,
          dataGridState?.pageIndex,
        )
    }

    return ReactDOM.createPortal(
        <section className='licensePopupBackdrop' >
            <div className='terminalEditPopup'>
                <h1>Редактировать информацию о терминале</h1>

                <div className='terminalEditPopupDataContainer'>
                    <EditDataBlock
                        title={'Лицензия'}
                        data={licenseData}
                        setData={setLicenseData}
                        fieldDataList={licenseFieldDataList}
                    />

                    <EditDataBlock
                        title={'Общие сведения'}
                        data={terminalCommonData}
                        setData={setTerminalCommonData}
                        fieldDataList={terminalCommonFieldDataList}
                    />

                    <HistoryDataBlock
                        title={'История изменений'}
                        dataList={terminalData.TerminalHistoryActions}
                    />
                </div>



                <div className='terminalEditPopupButtonContainer'>
                    <MyButton onClick={onClosePress} mod="myButton_white" >Закрыть</MyButton>
                    <MyButton onClick={onSaveButtonPress} mod='licensePopupSaveButton'>Сохранить</MyButton>
                    <MyButton onClick={onToggleActivationClick} mod='licensePopupSaveButton'>
                        {terminalData.LicenseCode.isActive ? 'Деактивировать' : 'Активировать'}
                    </MyButton>
                    <MyButton onClick={onRemoveTerminalClick} mod={cn('myButton_red', 'myButton_ml-15')}>Удалить</MyButton>
                </div>

                </div>
        </section>
      , window.document.body)
}
