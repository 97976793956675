import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import '../styles/LicensePopup.css'
import '../styles/TerminalsMapPopup.css'
import Map from './Map'
import MyButton from './MyButton'
import MyTextInput from './MyTextInput'

export default function MapPopup({ onClosePress, pointList }) {
    const [selectedPoint, setSelectedPoint] = useState(null)

    const onPointClick = (pointData) => {
        console.log('pointdata', pointData)
        setSelectedPoint(pointData)
    }

    const onEmptyClick = () => {
        setSelectedPoint(null)
    }

    const getLicenseStatusText = (isActive) => {
        return isActive !== false ? 'Активна' : 'Не активна'
    }

    return ReactDOM.createPortal(
        <section className='licensePopupBackdrop' >            
            <div className='terminalsMapPopup'>
                {
                    selectedPoint &&
                    <div className='terminalsMapPointInfoBlock'>
                        <h3>Информация о точке</h3>
                        <PointInfoRow title={'Клиент'} value={selectedPoint.LicenseCode.clientName}/>
                        <PointInfoRow title={'ИНН'} value={selectedPoint.LicenseCode.inn}/> 
                        <PointInfoRow title={'Номер заказа'} value={selectedPoint.LicenseCode.orderId}/>
                        <PointInfoRow title={'Код лицензии'} value={selectedPoint.LicenseCode.strId}/>
                        <PointInfoRow title={'Статус лицензии'} value={getLicenseStatusText(selectedPoint.LicenseCode.isActive)}/>
                        <PointInfoRow title={'IP-адрес'} value={selectedPoint.ipAddress}/>                  
                              
                    </div>
                }
                <Map pointList={pointList} onPointClick={onPointClick} onEmptyClick={onEmptyClick}/>
                <div>
                    <MyButton onClick={onClosePress} mod='licensePopupSaveButton'>Закрыть</MyButton>
                </div>
            </div>           
        </section>
      , window.document.body)
}

function PointInfoRow({ title, value }) {
    return (
        <div className='mapPointInfoRow'>
            <p>{title}:</p>
            <p>{value || 'не указан'}</p>
        </div>
    )
}