import React, { useState, useEffect, useRef } from 'react'
import { updateLicenseCodeData, updateTerminal } from '../api/license'
import { getTerminals } from '../api/terminals'
import Map from './Map'
import MapPopup from './MapPopup'
import MyButton from './MyButton'
import TerminalEditPopup from './TerminalEditPopup'
import TerminalList from './TerminalList'
import MoreDetailedTerminalPopup from "./MoreDetailedTerminalPopup";

export default function Terminals({}) {
    const [loading, setLoading] = useState(true)
    const [terminals, setTerminals] = useState({ total: null, data: [] })
    const [showTerminalsMap, setShowTerminalMap] = useState(false)
    const [terminalInConfig, setTerminalInConfig] = useState(false)
    const [showEditTerminalPopup, setShowEditTerminalPopup] = useState(false)
    const [dataGridState, setDataGridState] = useState(null)

    //update current terminal when list is updated
    useEffect(() => {
        if (terminalInConfig && terminals.data) {
            const terminal = terminals.data.find((terminal) => terminal.strId === terminalInConfig.strId)

            if (terminal) {
                setTerminalInConfig(terminal)
            }
        }
    }, [terminalInConfig, terminals])

    async function drawTerminals({ offset, limit }) {
        setLoading(true)

        const terminals = await getTerminals({ offset, limit })

        console.log(terminals)

        setTerminals(terminals)
        setLoading(false)
    }

    const onShowTerminalsMapPress = () => {
        setShowTerminalMap(true)
    }

    const onCloseTerminalsMapPress = () => {
        setShowTerminalMap(false)
    }

    const onCloseTerminalPopupPress = () => {
        setTerminalInConfig(null)
        setDataGridState(null)
        setShowEditTerminalPopup(false)
    }

    const onEditTerminalDataPress = () => {
        setShowEditTerminalPopup(true);
    }

    const onSaveTerminalPress = async ({ licenseData, terminalCommonData }) => {
        await updateLicenseCodeData(licenseData)
        await updateTerminal(terminalCommonData)

        await drawTerminals({ offset: 0, limit: 10 })
    }

    const onEditTerminalPress = ({terminalData, tableState}) => {
        setTerminalInConfig(terminalData)
        setDataGridState(tableState)
        setShowEditTerminalPopup(true)
    }

    const onMoreDetailedTerminalPress = ({ terminalData, tableState }) => {
        setTerminalInConfig(terminalData)
        setDataGridState(tableState)
        setShowEditTerminalPopup(false)
    }

    return (
        <section className="pageSection">
            <h1 className="sectionHeader" >Список терминалов</h1>
            <MyButton onClick={onShowTerminalsMapPress}>{!showTerminalsMap ? 'Показать' : 'Скрыть'} карту</MyButton>
            <h2>Список терминалов</h2>
            <hr className="licenseCodes__separator"/>
            {
                showTerminalsMap && <MapPopup onClosePress={onCloseTerminalsMapPress} pointList={terminals.data}/>
            }
            {
                Boolean(terminalInConfig) && showEditTerminalPopup &&
                <TerminalEditPopup
                    terminalData={terminalInConfig}
                    onClosePress={onCloseTerminalPopupPress}
                    onSavePress={onSaveTerminalPress}
                    drawTerminals={drawTerminals}
                    dataGridState={dataGridState}
                />
            }
            {
                Boolean(terminalInConfig) && !showEditTerminalPopup &&
                <MoreDetailedTerminalPopup
                    terminalData={terminalInConfig}
                    onClosePress={onCloseTerminalPopupPress}
                    onEditPress={onEditTerminalDataPress}
                />
            }
            <div className='terminalsTableWrapper'>
                <TerminalList
                    drawTerminals={drawTerminals}
                    loading={loading}
                    setLoading={setLoading}
                    terminals={terminals}
                    onEditTerminalPress={onEditTerminalPress}
                    onMoreDetailedTerminalPress={onMoreDetailedTerminalPress}
                />
            </div>
        </section>
    )
}
