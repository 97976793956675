import superagent from 'superagent'

async function getUsers() {
  const resp = await superagent.get('/api/user/list');
  return resp.body;
}

async function getUser(userId) {
  const resp = await superagent.get(`/api/user/${userId}`);
  return resp.body;
}

async function createUser(user) {
  const resp = await superagent.post('/api/user')
    .send(user)
    .set('Content-Type', 'application/json');
  return resp.body;
}

async function updateUser(userId, user) {
  const resp = await superagent.put(`/api/user/${userId}`)
    .send(user);
  return resp.body;
}

async function deleteUser(userId) {
  const resp = await superagent.delete(`/api/user/${userId}`);
  return resp.status;
}

async function changePassword(userId, oldPassword, newPassword) {
  const resp = await superagent.post('/api/user/changePassword')
    .send({ userId, oldPassword, newPassword });
  return resp.body;
}

export default {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  changePassword
}
