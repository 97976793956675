import React, { useState, useEffect } from 'react'
import { Dots } from 'react-preloaders'

import assemblyApi from '../api/assembly'

function AssemblyItem({ assembly }) {
  return (
    assembly &&
    <li className="assemblyItem_listItem statPage_assemblyItem" >
      <section className="listItem_section" >
        <span className="linkCaption" >{ assembly.branch.toUpperCase() }</span>
        <span className="linkCaption statPage_assemblyItem_version" >{ assembly.version }</span>
        <span className="linkCaption statPage_assemblyItem_setup" >{ assembly.setupCounter || 0 }</span>
      </section>
    </li>
  )
}

export default function ({ appId }) {
  const [ loading, setLoading ] = useState(true);
  const [ assemblies, setAssemblies ] = useState([]);
  const [ currentOrder, setCurrentOrder ] = useState({
    branch: 'none',
    version: 'none',
    setupCounter: 'none'
  });

  useEffect(() => {
    Promise.all([
      assemblyApi.getAssemblyList(appId, 'canary'),
      assemblyApi.getAssemblyList(appId, 'production')
    ]).then(([canary, production]) => {
      const arr = [...canary, ...production]
        .sort(sortByDate);
      setAssemblies(arr)
    })
      .then(() => setLoading(false))
      .catch(e => {
        setLoading(false);
        console.error('ERROR get branches', e.message)
      })
  }, [appId]);

  const sortByDate = (a, b) => (a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0);

  const sortByBranch = () => {
    const branches = ['none', 'canary', 'production'];
    const branch = branches[(branches.indexOf(currentOrder.branch) + 1) % 3];
    let arr = [];
    if (branch === 'none')
      arr = assemblies.sort(sortByDate);
    else
      arr = assemblies.sort((a, b) => (a.branch === branch && b.branch !== branch ? -1 : a.branch === b.branch ? 0 : 1));
    setAssemblies(arr);
    setCurrentOrder(cur => ({ ...cur, branch }))
  };

  const sortByVersion = () => {
    const directions = ['none', 'up', 'down'];
    const dir = directions[(directions.indexOf(currentOrder.version) + 1) % 3];
    let arr = [];
    if (dir === 'none')
      arr = assemblies.sort(sortByDate);
    else if (dir === 'up')
      arr = assemblies.sort((a, b) => {
        const aVersion = +a.version.split('.')[0];
        const bVersion = +b.version.split('.')[0];
        return aVersion < bVersion ? -1 : aVersion > bVersion ? 1 : 0
      });
    else
      arr = assemblies.sort((a, b) => {
        const aVersion = +a.version.split('.')[0];
        const bVersion = +b.version.split('.')[0];
        return aVersion > bVersion ? -1 : aVersion < bVersion ? 1 : 0
      });
    setAssemblies(arr);
    setCurrentOrder(cur => ({ ...cur, version: dir }))
  };

  const sortBySetupCounter = () => {
    const directions = ['none', 'up', 'down'];
    const dir = directions[(directions.indexOf(currentOrder.setupCounter) + 1) % 3];
    let arr = [];
    if (dir === 'none')
      arr = assemblies.sort(sortByDate);
    else if (dir === 'up')
      arr = assemblies.sort((a, b) => (a.setupCounter < b.setupCounter ? -1 : a.setupCounter > b.setupCounter ? 1 : 0));
    else
      arr = assemblies.sort((a, b) => (a.setupCounter > b.setupCounter ? -1 : a.setupCounter < b.setupCounter ? 1 : 0));
    setAssemblies(arr);
    setCurrentOrder(cur => ({ ...cur, setupCounter: dir }))
  };

  return (
    <section >
      { assemblies.length > 0 &&
        <ul className="genericItemList" >
          <li className="assemblyItem_listHeader" >
            <span className="section_tableHeader statPage_assemblyItem_branch" onClick={ sortByBranch } >Сборка</span>
            <span className="section_tableHeader statPage_assemblyItem_version" onClick={ sortByVersion } >Версия</span>
            <span className="section_tableHeader statPage_assemblyItem_setup" onClick={ sortBySetupCounter } >Кол-во установок</span>
          </li>
          { assemblies.map(assembly => <AssemblyItem assembly={ assembly } key={ assembly.id } />) }
        </ul>
      }
      <Dots customLoading={ loading } background="blur" color="#5e8adf" time={0} />
    </section>
  )
}