import React, { useState, useEffect, useRef } from 'react'
import { useParams, Redirect, Prompt, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Dots } from 'react-preloaders'
import { Line } from 'rc-progress'
import semver from 'semver'

import assemblyApi from '../api/assembly'
import appApi from '../api/applications'
import { logout } from '../api/auth'

import MyButton from './MyButton'
import MyTextInput from './MyTextInput'

import '../styles/EditForm.css'

export default function Assembly({ mainUser }) {
  const { appId } = useParams();
  const formRef = useRef(null);
  const [ appName, setAppName ] = useState('');
  const [ fileName, setFileName ] = useState(null);
  const [ defaultVersion, setDefaultVersion ] = useState(null);
  const [ isRedirectBack, setRedirectBack ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ progress, setProgress ] = useState(0);
  const [ errors, setErrors ] = useState({
    pattern: false,
    value: false,
    zip: true
  });
  const isTabletDevice = useMediaQuery({
    query: '(max-device-width: 550px)'
  });

  useEffect(() => {
    appApi.getActualVersion(appId)
      .then(({ version }) => {
        const splitted = version.split('.');
        setDefaultVersion(`${splitted[0]}.${splitted[1]}.${+splitted[2] + 1}`);
      })
      .then(() => setLoading(false));

    appApi.getApplication(appId)
      .then(app => setAppName(app.name))
      .then(() => setLoading(false))
  }, [appId]);

  const checkVersionError = (e) => {
    const value = e.target.value;
    if (!semver.valid(value)) {
      return setErrors(e => ({ ...e, pattern: true }));
    }
    if (semver.gt(defaultVersion, value)) {
      return setErrors(e => ({ ...e, value: true, pattern: false }));
    }
    setErrors(e => ({ ...e, value: false, pattern: false }))
  };

  const checkFileError = (value) => {
    setErrors(er => ({ ...er, zip: (!value) }))
  };

  const updateFile = (value) => {
    return (e) => {
      const fileValue = value === null ? value : formRef.current.dataFile.files[0].name;
      setFileName(fileValue);
      checkFileError(fileValue)
    }
  };

  const createAssembly = (e) => {
    e.preventDefault();
    setLoading(true);

    const file = formRef.current.dataFile.files[0];
    const saveData = {
      version: formRef.current.version.value,
      branch: formRef.current.branch.value,
      description: formRef.current.description.value,
      fileSize: Math.floor(file.size / 1024 / 1024 * 10) / 10 // Размер в Mb
    };

    if (Object.keys(errors).some(field => errors[field]))
      return;

    assemblyApi.uploadFile(file, setProgress)
      .catch(e => console.error('ERROR upload zip', e.message))
      .then(({ strId }) => assemblyApi.createAssembly({ ...saveData, strId, appStrId: appId }))
      .then(() => setLoading(false))
      .then(setRedirectBack(true))
      .catch(e => console.error('ERROR create assembly', e.message))
  };

  if (!loading && isRedirectBack) {
    return <Redirect to={{ pathname: `/admin/applications/${ appId }` }} />
  }

  return (
    <section>
      <nav className="mainNavMenu" >
        { isTabletDevice ? (
          <section className="mainNav_mobileTitle" >Новая сборка</section>
        ) : (
          <section className="submitSection" >
            <MyButton onClick={ createAssembly } disabled={ loading } mod="myButton_big" >Добавить</MyButton>
          </section>
        )}
        <ul className="mainNavList mainNavList__right mainNavList__rightResponsive" >
          { !isTabletDevice && <li className="mainNavListItem__right" >{ mainUser.firstName } { mainUser.lastName }</li> }
          <li className="mainNavListItem__right" ><button className="mainButton__red" onClick={ logout } >Выход</button></li>
        </ul>
      </nav>
      { isTabletDevice ? (
        <nav className="breadCrumbs" >
          <Link className="breadCrumbsLink" to={ `/admin/applications/${ appId }` } >{ `< ${appName}` }</Link>
        </nav>
      ) : (
        <nav className="breadCrumbs" >
          <Link className="breadCrumbsLink" to="/admin" >Приложения / </Link>
          <Link className="breadCrumbsLink" to={ `/admin/applications/${ appId }` } >{ appName } / </Link>
          Новая сборка
        </nav>
      )}
      <Prompt
        when={ true }
        message="Уйти без сохранения?"
      />
      <form ref={ formRef } onSubmit={ createAssembly }>
        <section className="editForm" >
          <div className="editFormField appField" >
            <label className="editFormField_label" htmlFor="version" >Версия</label>
            <MyTextInput
              hasError={ errors.value || errors.pattern }
              id="version"
              name="version"
              pattern="^\d+\.\d+\.\d+$"
              defaultValue={ defaultVersion }
              onKeyUp={ checkVersionError }
            />
            { errors.value && <span className="span-error" >Указанная версия меньше существующей</span> }
            { errors.pattern && <span className="span-error" >Указанная версия не соответствует шаблону</span> }
          </div>
          <div className="editFormField appField" >
            <label className="editFormField_label" htmlFor="description" >Текстовое описание</label>
            <textarea
              className={`appSection_textarea ${ errors.description && "error" }`}
              rows="4"
              name="description"
              placeholder="Что нового в этой сборке?"
            />
            { errors.description && <span className="span-error" >Поле не заполнено</span> }
          </div>
          <div className="editFormField" >
            <span className="editFormField_label" >Ветка</span>
            <input
              type="radio"
              name="branch"
              value="test"
              id="branch_test"
              defaultChecked={ null }
            />
            <label className="editFormField_inputLabel" htmlFor="branch_test" >
              <span className="editFormField_checkbox" />
              TEST
            </label>

            <input
              type="radio"
              name="branch"
              value="canary"
              id="branch_canary"
              defaultChecked="on"
            />
            <label className="editFormField_inputLabel" htmlFor="branch_canary" >
              <span className="editFormField_checkbox" />
              CANARY
            </label>

            <input
              type="radio"
              name="branch"
              value="production"
              id="branch_production"
              defaultChecked={ null }
            />
            <label className="editFormField_inputLabel" htmlFor="branch_production" >
              <span className="editFormField_checkbox" />
              PRODUCTION
            </label>
          </div>
          <div className="editFormField" >
            <span className="editFormField_label" >Архив с данными</span>
            <input
              type="file"
              name="dataFile"
              id="dataFile"
              accept="application/zip"
              onChange={ updateFile() }
            />
            { fileName ? (
              <div>
                <span className="editFormField_assemblyFileName" >{ fileName }</span>
                <span className="icon-close" onClick={ updateFile(null) } />
              </div>
            ) : (
            <label htmlFor="dataFile" className="myButton myButton_white editFormField_assemblyLabelBlock" >
              <span >Загрузить архив</span>
              <div className="uploadIcon editAssembly_uploadIcon" />
            </label>
            )}
            { errors.zip && <span className="span-error shiftLeft" >Необходимо выбрать файл</span> }
            { (progress > 0 && progress !== 100) && (
              <div>
                <p>Идет загрузка сборки</p>
                <Line percent={progress} strokeWidth={3} strokeColor="#5E8ADF" />
              </div>) }
          </div>
          { isTabletDevice && <section className="submitSection" >
            <MyButton onClick={ createAssembly } disabled={ loading } mod="myButton_big" >Добавить</MyButton>
          </section> }
        </section>
      </form>
      <Dots customLoading={ loading } background="blur" color="#5e8adf" time={0} />
    </section>
  )
}
