import React from "react";
import textConstants from "../utils/textConstants";


function getElementTranslation(elementName) {
  return textConstants[elementName] || elementName
}

function HistoryDataBlock({title, dataList = []}) {
  return (
    <div className='terminalEditPopupEditDataBlock terminalEditPopupListDataBlock'>
      <h3>{title}</h3>
      <div className='terminalEditPopupDataListContainer'>
        {
          !dataList.length &&
          (
            <p>Пусто</p>
          )
        }
        {
          [...dataList].map((historyEvent) => {
            const changedElements = []

            for (const elementName in historyEvent.changes) {
              const changeInfo = historyEvent.changes[elementName]
              const {action, oldValue, newValue} = changeInfo
              let changeText = ''

              switch (action) {
                case 'edit':
                  changeText = `Изменено с "${oldValue}" на "${newValue}"`
                  break;
                case 'remove':
                  changeText = `Удалено "${oldValue}"`
                  break
                case 'add':
                  changeText = `Добавлено "${newValue}"`
                  break
              }

              changedElements.push((
                <>
                  <p><b>{getElementTranslation(elementName)}:</b> {changeText}</p>
                </>
              ))
            }

            return (
              <div className='terminalEditPopupHistoryItem' key={historyEvent.strId}>
                <h3>
                  {
                    (new Date(historyEvent.createdAt)).toLocaleString('ru-RU')
                  }
                  {
                    historyEvent.User &&
                    `(${historyEvent.User.firstName} ${historyEvent.User.lastName.slice(0, 1)}.)`
                  }
                </h3>
                {
                  changedElements
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default HistoryDataBlock
