import React from 'react'
import classNames from 'classnames'
import wrapModifications from '../utils/wrapModifications'
import '../styles/MyButton.css'

export default function (props) {
  const totalProps = {
    className: classNames('myButton', {
      ...wrapModifications(props.mod)
    }),
    ...props
  };
  return <button { ...totalProps } />
}
