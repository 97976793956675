import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Dots } from 'react-preloaders'
import moment from 'moment'
import 'moment/locale/ru'

import appApi from '../api/applications'

moment.locale('ru');

function AppItem({ app }) {
  const isTabletDevice = useMediaQuery({
    query: '(max-device-width: 700px)'
  });

  return (
    <Link to={ `/admin/statistics/${ app.strId }` } className="genericItemList_Item appItem_listItem" >
      <section className="listItem_section" >
        <div className="linkImage" >
          { app.avatar ? <img src={ app.avatar } alt="app_icon" /> : <div /> }
        </div>
        <span className="linkCaption appItem_nameValue" >{ app.name }</span>
        { !isTabletDevice && <span className="linkCaption appItem_date" >{ moment(app.updatedAt).format('D MMMM, YYYY') }</span> }
      </section>
    </Link>
  )
}

export default function () {
  const [ loading, setLoading ] = useState(true);
  const [ apps, setApps ] = useState(null);
  const isTabletDevice = useMediaQuery({
    query: '(max-device-width: 700px)'
  });
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 420px)'
  });

  useEffect(() => {
    appApi.getApplicationList()
      .then(setApps)
      .then(() => setLoading(false))
      .catch(e => {
        setLoading(false);
        console.error('ERROR get application list', e.message)
      });
  }, []);

  return ( apps &&
    <section className="pageSection" >
      { !isMobileDevice && <h1 className="sectionHeader appPageHeader" >Статистика</h1> }
      <ul className="genericItemList" >
        { !isMobileDevice &&
          <li className="appItem_listHeader" >
            <span className="section_tableHeader" >Иконка</span>
            <span className="section_tableHeader appItem_nameHeader" >Название приложения</span>
            { !isTabletDevice &&
              <span className="section_tableHeader appItem_date" >Последнее обновление</span>
            }
          </li>
        }
        { apps.map(app => <AppItem key={ app.id } app={ app } />) }
      </ul>
      <Dots customLoading={ loading } background="blur" color="#5e8adf" time={0} />
    </section>
  )
}